import { Button, Typography } from "antd";
import { ObjectJournalsList } from "../object-journals-list";
import { useNavigate } from "react-router-dom";
import { routes } from "@/shared/lib/react-router";

const { Title } = Typography;

export const ObjectJournals = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(routes.journals.title());
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Title level={4}>Список журналов обьекта</Title>
        <Button type="default" onClick={handleNavigate}>
          Создать ОЖР
        </Button>
      </div>
      <ObjectJournalsList />
    </>
  );
};
