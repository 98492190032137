import React, { useState } from "react";
import {
  Typography,
  Select,
  Button,
  Row,
  Col,
  Divider,
  Dropdown,
  Menu,
} from "antd";
import { BasketIcon, MoreOneIcon, EditIcon } from "@/shared/assets/svg/icons";
import PdfIcon from "@/shared/assets/svg/icons/pdf-icon.png";
import SigIcon from "@/shared/assets/svg/icons/sig-icon.png";
import { useDisclosure } from "@/shared/lib/hooks";
import { RepresentationMoodal } from "@/features/representation-modal";

import "./journals-supervision-table.scss";
import { Link } from "react-router-dom";

type Organization = {
  id: number;
  name: string;
  inn: string;
  kpp: string;
  ogrn: string;
  hasEmployee?: boolean;
};

type EmployeeData = {
  key: number;
  title: string;
  info: string;
  isCopy?: boolean;
  isEmail?: boolean;
};

type AdditionalData = {
  section: string;
  number: string;
};

const mockOrganizations: Organization[] = [
  {
    id: 1,
    name: "Проектное бюро",
    inn: "7839136848",
    kpp: "783901001",
    ogrn: "1217800024265",
  },
  {
    id: 2,
    name: "СРО Проектирование",
    inn: "7734268438",
    kpp: "773401001",
    ogrn: "1097799009428",
  },
  {
    id: 3,
    name: "Архитектурное бюро",
    inn: "7743068541",
    kpp: "774301001",
    ogrn: "1177799000123",
  },
  {
    id: 4,
    name: "Инжиниринговая компания",
    inn: "7841012345",
    kpp: "784101002",
    ogrn: "1207800023489",
  },
];

const employeeMockData: EmployeeData[] = [
  { key: 1, title: "Организация", info: "ООО “Проектировщик”" },
  { key: 2, title: "Должность", info: "Проектировщик" },
  {
    key: 3,
    title: "Документ, №, дата",
    info: "Приказ 66, 12.10.2021, подпись.sig",
    isCopy: true,
  },
  { key: 4, title: "Почта", info: "enadzor2000@mail.ru", isEmail: true },
  { key: 5, title: "Номер в НОПРИЗ", info: "-" },
  { key: 6, title: "Телефон", info: "8 (921) 123-3210" },
];

const additionalData: AdditionalData[] = [
  { section: "Раздел", number: "Номер" },
  { section: "Водоснабжение", number: "112" },
  { section: "Вентиляция и кондиционирование", number: "144" },
  { section: "РД", number: "231" },
];

export const JournalsSupervisionTable: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAddEmployee = () => {
    setOrganizations((prev) =>
      prev.map((org) =>
        org.id === selectedOrgId ? { ...org, hasEmployee: true } : org,
      ),
    );
    setSelectedOrgId(null);
    onClose();
  };

  const handleAddOrganization = (orgId: number) => {
    const selectedOrg = mockOrganizations.find((org) => org.id === orgId);
    if (selectedOrg) {
      setOrganizations((prev) => [
        ...prev,
        { ...selectedOrg, hasEmployee: false },
      ]);
    }
  };

  const handleEditEmployee = () => {
    onOpen();
  };

  const handleDeleteEmployee = (orgId: number) => {
    setOrganizations((prev) =>
      prev.map((org) =>
        org.id === orgId ? { ...org, hasEmployee: false } : org,
      ),
    );
  };

  return (
    <div className="table-container" id="journals-supervision-table">
      <Typography.Title level={4}>Авторский надзор</Typography.Title>
      <div style={{ marginTop: "14px" }}>
        {organizations.map((org) => (
          <div key={org.id} style={{ marginBottom: "24px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography.Title level={5}>{org.name}</Typography.Title>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="delete"
                      icon={<BasketIcon />}
                      onClick={() =>
                        setOrganizations((prev) =>
                          prev.filter((item) => item.id !== org.id),
                        )
                      }
                    >
                      Удалить
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <MoreOneIcon
                  style={{ cursor: "pointer" }}
                  width={24}
                  height={24}
                />
              </Dropdown>
            </div>
            <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
              <Col span={8} style={{ color: "#888888" }}>
                ИНН
              </Col>
              <Col span={16}>{org.inn}</Col>
              <Col span={8} style={{ color: "#888888" }}>
                КПП
              </Col>
              <Col span={16}>{org.kpp}</Col>
              <Col span={8} style={{ color: "#888888" }}>
                ОГРН
              </Col>
              <Col span={16}>{org.ogrn}</Col>
            </Row>
            {org.hasEmployee ? (
              <div style={{ marginTop: "16px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography.Title level={5} style={{ fontWeight: "400" }}>
                    Надзорный Егор
                  </Typography.Title>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="edit"
                          icon={<EditIcon />}
                          onClick={handleEditEmployee}
                        >
                          Редактировать
                        </Menu.Item>
                        <Menu.Item
                          key="delete"
                          icon={<BasketIcon />}
                          onClick={() => handleDeleteEmployee(org.id)}
                        >
                          Удалить
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <MoreOneIcon
                      style={{ cursor: "pointer" }}
                      width={24}
                      height={24}
                    />
                  </Dropdown>
                </div>
                <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                  {employeeMockData.map(
                    ({ info, isEmail, isCopy, title, key }) => (
                      <React.Fragment key={key}>
                        <Col span={8} style={{ color: "#888888" }}>
                          {title}
                        </Col>
                        <Col span={16} className="table-info-content">
                          {isEmail ? <Link to={info}>{info}</Link> : null}
                          {!isEmail && !isCopy ? <div>{info}</div> : null}
                          {isCopy ? (
                            <div className="flex-between">
                              <div>{info}</div>
                              <div className="flex-default">
                                <img
                                  width={16}
                                  height={16}
                                  alt=""
                                  src={PdfIcon}
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                />
                                <img
                                  width={16}
                                  height={16}
                                  alt=""
                                  src={SigIcon}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          ) : null}
                        </Col>
                      </React.Fragment>
                    ),
                  )}
                </Row>
                <Divider style={{ margin: "16px 0" }} />
                <div
                  style={{ marginTop: "16px", paddingBottom: "32px" }}
                  className="table-border-custom"
                >
                  <Typography.Text
                    style={{
                      display: "block",
                      marginBottom: "8px",
                      fontWeight: 500,
                    }}
                  >
                    Сведения о разделах проектной документации, подготовленных
                    этим лицом
                  </Typography.Text>
                  <Row gutter={[16, 16]} style={{ fontWeight: 400 }}>
                    {additionalData.map((data, index) => (
                      <React.Fragment key={data.section}>
                        {index === 0 ? (
                          <>
                            <Col
                              span={12}
                              style={{
                                color: "#888888",
                              }}
                              className="table-border-custom"
                            >
                              {data.section}
                            </Col>
                            <Col
                              span={12}
                              style={{
                                color: "#888888",
                                textAlign: "right",
                                borderBottom: "1px sloid #888888",
                              }}
                              className="table-border-custom"
                            >
                              {data.number}
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={12} className="table-border-custom">
                              {data.section}
                            </Col>
                            <Col
                              span={12}
                              style={{ textAlign: "right" }}
                              className="table-border-custom"
                            >
                              {data.number}
                            </Col>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </Row>
                </div>
              </div>
            ) : (
              <Button
                type="primary"
                style={{ marginTop: "16px" }}
                onClick={() => {
                  setSelectedOrgId(org.id);
                  onOpen();
                }}
              >
                Добавить сотрудника
              </Button>
            )}
          </div>
        ))}
        {/* <Divider /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "16px",
          }}
        >
          <Typography.Text style={{ marginBottom: "4px" }}>
            Организация
          </Typography.Text>
          <Select
            placeholder="Выберите организацию"
            style={{ width: "50%", marginBottom: "16px" }}
            onChange={handleAddOrganization}
          >
            {mockOrganizations.map((org) => (
              <Select.Option key={org.id} value={org.id}>
                {org.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <RepresentationMoodal
        isRepresentation={isOpen}
        handleClose={onClose}
        handleAdd={handleAddEmployee}
        type="author"
      />
    </div>
  );
};
