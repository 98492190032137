import { z } from "zod";
import {
  LoginUserDtoSchema,
  RegisterUserDtoSchema,
  UserDtoSchema,
  UserModulesDtoSchema,
} from "./contracts";

export type UserResponse = z.infer<typeof UserDtoSchema>;
export type UserModulesDto = z.infer<typeof UserModulesDtoSchema>;
export type LoginUserDto = z.infer<typeof LoginUserDtoSchema>;
export type RegisterUserDto = z.infer<typeof RegisterUserDtoSchema>;

export enum Roles {
  UNDEFINED_ROLE = 0,
  ADMIN = "ADMIN",
  USER = "USER",
  WORKER = "WORKER",
  PRORAB = "PRORAB",
}
