import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { JournalsHistory } from "./ui";

export const JournalsHistoryRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.journals.history()}`,
    element: createElement(JournalsHistory),
    children: [...rest],
  };
};
