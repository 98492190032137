type EnvType = {
  API_BASE_URL: string;
  DADATA_API_KEY: string;
  OLD_FRONTEND_URL: string;
  ACTUAL_FRONTEND: string;
};
export const env: EnvType = {
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  DADATA_API_KEY: import.meta.env.VITE_DADATA_API_KEY,
  OLD_FRONTEND_URL: import.meta.env.VITE_OLD_FRONTEND_URL,
  ACTUAL_FRONTEND: import.meta.env.VITE_ACTUAL_FRONTEND,
};
