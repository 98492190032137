import React, { useState } from "react";
import { Typography, Button, Row, Col, Tooltip } from "antd";
import { RepresentationMoodal } from "@/features/representation-modal";
import { DeleteOutlined } from "@ant-design/icons"; // Используем иконку корзинки из Ant Design
import "./settings-title-module.scss";
import { BasketIcon } from "@/shared/assets/svg/icons";

type User = {
  id: number;
  name: string;
  organization: string;
  position: string;
  email: string;
  phone: string;
};

const mockUser = (id: number): User => ({
  id,
  name: `Пользователь ${id}`,
  organization: "ООО “Интент”",
  position: "Дизайнер",
  email: `user${id}@example.com`,
  phone: `+7 (921) 334-400${id}`,
});

const Section = ({
  title,
  users,
  onOpenModal,
  onDeleteUser,
}: {
  title: string;
  users: User[];
  onOpenModal: () => void;
  onDeleteUser: (userId: number) => void;
}) => (
  <div className="section">
    {users.length > 0 ? (
      <div className="flex-between" style={{ marginBottom: "24px" }}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Button type="link" onClick={onOpenModal}>
          Добавить
        </Button>
      </div>
    ) : (
      <Typography.Title level={5}>{title}</Typography.Title>
    )}

    {users.length > 0 && (
      <Row className="table-header" gutter={[16, 16]}>
        <Col span={5}>
          <Typography.Text style={{ color: "#666666" }}>ФИО</Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text style={{ color: "#666666" }}>
            Организация
          </Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text style={{ color: "#666666" }}>
            Должность
          </Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text style={{ color: "#666666" }}>Email</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text style={{ color: "#666666" }}>
            Телефон
          </Typography.Text>
        </Col>
      </Row>
    )}

    {users.map((user) => (
      <Row key={user.id} className="user-row" gutter={[16, 16]} align="middle">
        <Col span={5}>{user.name}</Col>
        <Col span={5}>{user.organization}</Col>
        <Col span={5}>{user.position}</Col>
        <Col span={5}>{user.email}</Col>
        <Col span={3}>{user.phone}</Col>
        <Col span={1} style={{ textAlign: "right" }}>
          <Button
            type="text"
            icon={
              <Tooltip title="Удалить сотрудника">
                <div className="basket-container">
                  <BasketIcon />
                </div>
              </Tooltip>
            }
            onClick={() => onDeleteUser(user.id)}
          />
        </Col>
      </Row>
    ))}

    {users.length === 0 ? (
      <Button
        type="primary"
        className="add-user-button"
        onClick={onOpenModal}
        style={{ marginTop: "16px" }}
      >
        Добавить сотрудника
      </Button>
    ) : null}
  </div>
);

export const SettingsTitleModule: React.FC = () => {
  const [sections, setSections] = useState<
    { id: number; title: string; users: User[] }[]
  >([
    { id: 1, title: "Заполнение данных", users: [] },
    { id: 2, title: "Проверка данных", users: [] },
    { id: 3, title: "Отправка в ГСН", users: [] },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [currentSectionId, setCurrentSectionId] = useState<number | null>(null);

  const handleAddUser = () => {
    if (currentSectionId !== null) {
      setSections((prevSections) =>
        prevSections.map((section) =>
          section.id === currentSectionId
            ? {
                ...section,
                users: [...section.users, mockUser(section.users.length + 1)],
              }
            : section,
        ),
      );
      setIsOpen(false);
    }
  };

  const handleDeleteUser = (sectionId: number, userId: number) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              users: section.users.filter((user) => user.id !== userId),
            }
          : section,
      ),
    );
  };

  const handleOpenModal = (sectionId: number) => {
    setCurrentSectionId(sectionId);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setCurrentSectionId(null);
  };

  return (
    <div className="table-container table-container-custom">
      <Typography.Title level={4} style={{ marginBottom: "0px" }}>
        Титульный лист
      </Typography.Title>
      {sections.map((section) => (
        <Section
          key={section.id}
          title={section.title}
          users={section.users}
          onOpenModal={() => handleOpenModal(section.id)}
          onDeleteUser={(userId) => handleDeleteUser(section.id, userId)}
        />
      ))}
      <RepresentationMoodal
        isRepresentation={isOpen}
        handleClose={handleCloseModal}
        handleAdd={handleAddUser}
        type="settings"
      />
    </div>
  );
};
