import { Card, Flex, Skeleton } from "antd";

import "./object-card-skeleton.scss";
import { useId } from "react";

export const ObjectCardSkeleton = () => {
  const id = useId();
  return (
    <div className="object-page-skeleton-grid">
      {Array.from({ length: 3 }, (_, index) => (
        <Card className="object-card-skeleton" key={id + _ + index}>
          <Flex vertical>
            <Skeleton active />
          </Flex>
        </Card>
      ))}
    </div>
  );
};
