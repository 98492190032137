import { z } from "zod";
import { userTypesDto } from "@shared/api/user";

export const UserModulesDtoSchema = z.object({
  modules: z.string().array(),
});

export const LoginUserDtoSchema = z.object({
  login: z.string().min(0).max(32),
  password: z.string().min(8).max(32),
});

export const RegisterUserDtoSchema = z.object({
  login: z.string().min(0).max(32),
  password: z.string().min(8).max(32),
});

export const PositionDtoSchema = z.object({
  createDateTime: z.string(),
  description: z.string(),
  inn: z.string(),
  positionId: z.string(),
  value: z.string(),
});

export const EmploymentDtoSchema = z.object({
  employmentId: z.string(),
  employmentEndDate: z.string(),
  employmentOrder: z.string(),
  employmentOrderDate: z.string(),
  employmentStartDate: z.string(),
  position: PositionDtoSchema,
});

export const RoleDtoSchema = z.object({
  permissions: z.array(
    z.object({
      permissionId: z.number(),
      permissionName: z.string(),
      permissionDescription: z.string(),
    }),
  ),
  roleDescription: z.string(),
  roleId: z.nativeEnum(userTypesDto.Roles),
  roleName: z.string(),
});

export const UserDtoSchema = z.object({
  historyId: z.string(),
  userId: z.string(),
  firstName: z.string(),
  surname: z.string(),
  middleName: z.string(),
  mobilePhone: z.string(),
  email: z.string(),
  dateOfBirth: z.string(),
  hasLoggedIn: z.boolean(),
  userAvatarId: z.string(),
  isBlock: z.boolean(),
  employment: EmploymentDtoSchema,
});
