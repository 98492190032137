import { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, notification } from "antd";
import "./representation-form-email.scss";
import { User } from "@entities/user";
import { useFindUserByEmailMutation } from "@/entities/user/queries/use-find-user-by-email-mutation";

interface IRepresentationFormEmail {
  setIsShowFormAdd: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (user: User) => void;
  error?: string;
}

export const RepresentationFormEmail = ({
  setIsShowFormAdd,
  onSuccess,
  error,
}: IRepresentationFormEmail) => {
  const [emailValue, setEmailValue] = useState("");
  const { mutateAsync, isPending } = useFindUserByEmailMutation();
  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };
  const [api, contextHolder] = notification.useNotification();

  const handleAddRepresentation = () => {
    mutateAsync({ email: emailValue })
      .then((result) => {
        if (onSuccess) {
          console.log(result);
          if (!result.data) {
            api.error({
              message: `Сотрудник не найден`,
              description: "Попробуйте другие данные",
              placement: "topRight",
              showProgress: false,
            });
          } else {
            onSuccess(result.data as User);
            setIsShowFormAdd && setIsShowFormAdd(true);
          }
        }
      })
      .catch(() => {
        api.error({
          message: `Неудачный запрос`,
          description:
            "Это временно, потом сделаем крутую обработку и подсвечивание инпута. Пока добавляем временного пользователя",
          placement: "topRight",
          showProgress: false,
        });
        onSuccess({
          historyId: "abcd1234-5678-ef90-ghij-klmnop123456",
          userId: "user1234-5678-abcd-ef90-klmnop567890",
          firstName: "Дмитрий",
          surname: "Харин",
          middleName: "Андреевич",
          mobilePhone: "+79061234567",
          email: "dmitriy.kharin@example.com",
          dateOfBirth: "1995-09-20",
          hasLoggedIn: true,
          userAvatarId: "avatar1234-abcd-5678-ef90-ghijklmn5678",
          isBlock: false,
          employment: {
            employmentId: "empl1234-abcd-efgh-ijkl-mnop12345678",
            employmentEndDate: "2025-12-31",
            employmentOrder: "№54321",
            employmentOrderDate: "2020-02-10",
            employmentStartDate: "2015-05-15",
            position: {
              createDateTime: "2015-05-15T09:00:00Z",
              description:
                "Разработка проектной документации и управление подрядными работами",
              inn: "098765432109",
              positionId: "pos5678-abcd-efgh-ijkl-mnop87654321",
              value: "Менеджер по проектной документации",
            },
          },
        });
        setIsShowFormAdd(true);
      });
    // if (setIsShowFormAdd) {
    //   setIsShowFormAdd(true);
    // }
  };
  useEffect(() => {
    error &&
      api.error({
        message: `Неудачный запрос`,
        description: error,
        placement: "topRight",
        showProgress: false,
      });
  }, [error]);
  return (
    <div className="email-input-container">
      {contextHolder}
      <div className="email-inpit-lable">
        <div className="email-input-title">Электронная почта</div>
        <Input
          placeholder="почта"
          style={{ minWidth: "320px" }}
          value={emailValue}
          onChange={handleEmail}
        />
      </div>
      <Button
        type="primary"
        loading={isPending}
        disabled={emailValue.length < 11}
        onClick={handleAddRepresentation}
      >
        Найти
      </Button>
    </div>
  );
};
