import { Button, Flex } from "antd";
import type { Project } from "@entities/project";
import { CreateProjectWrapper } from "@pages/projects/ui/create-project-wrapper/create-project-wrapper.tsx";
import "./project-list.scss";
import { ProjectCard } from "@pages/projects/ui/project-list/ui/project-card.tsx";

interface ProjectListProps {
  projects: Array<Project>;
}

export const ProjectList = ({ projects }: ProjectListProps) => {
  return (
    <Flex
      component="main"
      className="project-screen"
      vertical
      justify="space-between"
    >
      <div className="project-grid">
        {projects?.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
      <div className="project-button-wrapper">
        <CreateProjectWrapper>
          {({ onOpen }) => (
            <Button
              type="primary"
              size="middle"
              className="project-main-button-create"
              onClick={onOpen}
            >
              Создать проект
            </Button>
          )}
        </CreateProjectWrapper>
      </div>
    </Flex>
  );
};
