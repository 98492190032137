import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import ProjectsPage from "@pages/projects/ui/projects-page.tsx";
import { routes } from "@shared/lib/react-router";

export const ProjectsPageRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.projects()}`,
    element: createElement(ProjectsPage),
    children: [...rest],
  };
};

export { ProjectPageSkeleton } from "./ui/project-skeleton";
