import { useState } from "react";
import { CrossStorageClient } from "cross-storage";
import { env } from "@/shared/config";
import { TokenManipulate } from "../session-service";

const storage = new CrossStorageClient(env.OLD_FRONTEND_URL, {});

export const useAuth = ({ time = 3000 }: { time?: number }) => {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isProccesing, setIsProccesing] = useState(true);

  const navigateToOldDomain = () => {
    setTimeout(() => {
      window.location.href = `${env.OLD_FRONTEND_URL}/sign-in?redirectTo=${env.ACTUAL_FRONTEND}`;
      console.log("navigate to", `${env.OLD_FRONTEND_URL}/sign-in`);
    }, time);
  };

  storage
    .onConnect()
    .then(() => {
      return storage.get("token", "newToken", "newIat", "iat");
    })
    .then((tokens) => {
      if (tokens[0] === null || tokens[1] === null) throw new Error();
      const newToken = localStorage.getItem("newToken");
      const token = localStorage.getItem("token");
      if (!newToken || !token) {
        TokenManipulate.print({
          newToken: tokens[1],
          token: tokens[0],
          newIat: tokens[2],
          iat: tokens[3],
        });
      }

      setIsAuth(true);
    })
    .catch((err) => {
      console.error(err);
      setIsAuth(false);
      navigateToOldDomain();
    })
    .finally(() => {
      setIsProccesing(false);
    });
  return {
    isAuth,
    isProccesing,
  };
};
