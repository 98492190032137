export const ObjectPatch = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 3.25V22C4.75 22.4142 4.41421 22.75 4 22.75C3.58579 22.75 3.25 22.4142 3.25 22V3C3.25 2.30965 3.80965 1.75 4.5 1.75H14.5C15.1904 1.75 15.75 2.30966 15.75 3V22C15.75 22.4142 15.4142 22.75 15 22.75C14.5858 22.75 14.25 22.4142 14.25 22V3.25H4.75Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.357 7.11427C14.5701 6.75908 15.0308 6.64388 15.386 6.85697L20.386 9.85662C20.6119 9.99216 20.7501 10.2363 20.7501 10.4998V22.0001C20.7501 22.4143 20.4143 22.7501 20.0001 22.7501C19.5859 22.7501 19.2501 22.4143 19.2501 22.0001V10.9244L14.6143 8.14325C14.2591 7.93016 14.1439 7.46947 14.357 7.11427Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 22C1.25 21.5858 1.58579 21.25 2 21.25H22C22.4142 21.25 22.75 21.5858 22.75 22C22.75 22.4142 22.4142 22.75 22 22.75H2C1.58579 22.75 1.25 22.4142 1.25 22Z"
        fill="#333333"
      />
    </>
  );
};

export const RequisitesPatch = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 5.25C12.4143 5.25 12.7501 5.58579 12.7501 6V11.567L17.625 14.3815C17.9837 14.5886 18.1067 15.0473 17.8995 15.406C17.6924 15.7648 17.2337 15.8877 16.875 15.6806L12.0001 12.866L7.12512 15.6806C6.7664 15.8877 6.30771 15.7648 6.10061 15.406C5.8935 15.0473 6.01641 14.5886 6.37513 14.3815L11.2501 11.567V6C11.2501 5.58579 11.5859 5.25 12.0001 5.25Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6.75C4.30965 6.75 3.75 7.30966 3.75 8C3.75 8.69034 4.30965 9.25 5 9.25C5.69034 9.25 6.25 8.69034 6.25 8C6.25 7.30966 5.69034 6.75 5 6.75ZM2.25 8C2.25 6.48124 3.48121 5.25 5 5.25C6.51876 5.25 7.75 6.48124 7.75 8C7.75 9.51876 6.51876 10.75 5 10.75C3.48121 10.75 2.25 9.51876 2.25 8Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 14.75C4.30965 14.75 3.75 15.3097 3.75 16C3.75 16.6903 4.30965 17.25 5 17.25C5.69034 17.25 6.25 16.6903 6.25 16C6.25 15.3097 5.69034 14.75 5 14.75ZM2.25 16C2.25 14.4812 3.48121 13.25 5 13.25C6.51876 13.25 7.75 14.4812 7.75 16C7.75 17.5188 6.51876 18.75 5 18.75C3.48121 18.75 2.25 17.5188 2.25 16Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.75C11.3097 18.75 10.75 19.3097 10.75 20C10.75 20.6903 11.3097 21.25 12 21.25C12.6903 21.25 13.25 20.6903 13.25 20C13.25 19.3097 12.6903 18.75 12 18.75ZM9.25 20C9.25 18.4812 10.4812 17.25 12 17.25C13.5188 17.25 14.75 18.4812 14.75 20C14.75 21.5188 13.5188 22.75 12 22.75C10.4812 22.75 9.25 21.5188 9.25 20Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 14.75C18.3097 14.75 17.75 15.3097 17.75 16C17.75 16.6903 18.3097 17.25 19 17.25C19.6903 17.25 20.25 16.6903 20.25 16C20.25 15.3097 19.6903 14.75 19 14.75ZM16.25 16C16.25 14.4812 17.4812 13.25 19 13.25C20.5188 13.25 21.75 14.4812 21.75 16C21.75 17.5188 20.5188 18.75 19 18.75C17.4812 18.75 16.25 17.5188 16.25 16Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 6.75C18.3097 6.75 17.75 7.30966 17.75 8C17.75 8.69034 18.3097 9.25 19 9.25C19.6903 9.25 20.25 8.69034 20.25 8C20.25 7.30966 19.6903 6.75 19 6.75ZM16.25 8C16.25 6.48124 17.4812 5.25 19 5.25C20.5188 5.25 21.75 6.48124 21.75 8C21.75 9.51876 20.5188 10.75 19 10.75C17.4812 10.75 16.25 9.51876 16.25 8Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75C11.3097 2.75 10.75 3.30965 10.75 4C10.75 4.69034 11.3097 5.25 12 5.25C12.6903 5.25 13.25 4.69034 13.25 4C13.25 3.30965 12.6903 2.75 12 2.75ZM9.25 4C9.25 2.48121 10.4812 1.25 12 1.25C13.5188 1.25 14.75 2.48121 14.75 4C14.75 5.51876 13.5188 6.75 12 6.75C10.4812 6.75 9.25 5.51876 9.25 4Z"
        fill="#333333"
      />
    </>
  );
};

export const StatisticsPatch = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 2.77997C6.49211 3.16173 2.75 7.14385 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C16.8561 21.25 20.8383 17.5079 21.22 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12V2.77997ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V11.25H22C22.4142 11.25 22.75 11.5858 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5562 1.85343C14.7487 1.71213 14.9969 1.67093 15.2247 1.74248C18.5689 2.7928 21.2074 5.4314 22.2577 8.77552C22.3293 9.00332 22.2881 9.25159 22.1468 9.44407C22.0055 9.63655 21.781 9.75025 21.5422 9.75025H15C14.5858 9.75025 14.25 9.41446 14.25 9.00025V2.45801C14.25 2.21923 14.3637 1.99473 14.5562 1.85343ZM15.75 3.54172V8.25025H20.4585C19.5288 6.1565 17.8437 4.47147 15.75 3.54172Z"
        fill="#333333"
      />
    </>
  );
};

export const DocstorePatch = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 3C10.25 2.58579 10.5858 2.25 11 2.25H16C16.4142 2.25 16.75 2.58579 16.75 3V21C16.75 21.4142 16.4142 21.75 16 21.75H11C10.5858 21.75 10.25 21.4142 10.25 21V3ZM11.75 3.75V20.25H15.25V3.75H11.75Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 3C15.25 2.58579 15.5858 2.25 16 2.25H21C21.4142 2.25 21.75 2.58579 21.75 3V21C21.75 21.4142 21.4142 21.75 21 21.75H16C15.5858 21.75 15.25 21.4142 15.25 21V3ZM16.75 3.75V20.25H20.25V3.75H16.75Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53649 2.41043C4.69394 2.28663 4.89436 2.23095 5.09309 2.25579L9.09309 2.75579C9.49695 2.80628 9.78684 3.16965 9.74635 3.57463L7.99635 21.0746C7.97633 21.2748 7.87673 21.4586 7.71992 21.5846C7.56311 21.7106 7.36224 21.7684 7.16244 21.7449L2.91244 21.2449C2.50203 21.1966 2.208 20.8254 2.25492 20.4148L4.25492 2.91484C4.27766 2.71585 4.37905 2.53423 4.53649 2.41043ZM5.65915 3.83823L3.8301 19.8425L6.57973 20.166L8.18098 4.15345L5.65915 3.83823Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 6.75C18.9142 6.75 19.25 7.08579 19.25 7.5V9C19.25 9.41421 18.9142 9.75 18.5 9.75C18.0858 9.75 17.75 9.41421 17.75 9V7.5C17.75 7.08579 18.0858 6.75 18.5 6.75Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 6.75C13.9142 6.75 14.25 7.08579 14.25 7.5V9C14.25 9.41421 13.9142 9.75 13.5 9.75C13.0858 9.75 12.75 9.41421 12.75 9V7.5C12.75 7.08579 13.0858 6.75 13.5 6.75Z"
        fill="#333333"
      />
    </>
  );
};
export const JournalsPatch = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5C5.75 4.58579 6.08579 4.25 6.5 4.25H20.5C20.9142 4.25 21.25 4.58579 21.25 5V22C21.25 22.4142 20.9142 22.75 20.5 22.75H6.5C6.08579 22.75 5.75 22.4142 5.75 22V5ZM7.25 5.75V21.25H19.75V5.75H7.25Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 2.5C2.75 1.80965 3.30965 1.25 4 1.25H17.5C17.9142 1.25 18.25 1.58579 18.25 2V5C18.25 5.41421 17.9142 5.75 17.5 5.75C17.0858 5.75 16.75 5.41421 16.75 5V2.75H4.25V18.25H6.5C6.91421 18.25 7.25 18.5858 7.25 19C7.25 19.4142 6.91421 19.75 6.5 19.75H3.5C3.08579 19.75 2.75 19.4142 2.75 19V2.5Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 11C9.75 10.5858 10.0858 10.25 10.5 10.25H16.5C16.9142 10.25 17.25 10.5858 17.25 11C17.25 11.4142 16.9142 11.75 16.5 11.75H10.5C10.0858 11.75 9.75 11.4142 9.75 11Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 15C9.75 14.5858 10.0858 14.25 10.5 14.25H16.5C16.9142 14.25 17.25 14.5858 17.25 15C17.25 15.4142 16.9142 15.75 16.5 15.75H10.5C10.0858 15.75 9.75 15.4142 9.75 15Z"
        fill="#333333"
      />
    </>
  );
};
