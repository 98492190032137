import { DeleteOutlined } from "@ant-design/icons";
import { Card, Row, Col, Button, Typography } from "antd";
import { Link } from "react-router-dom";
import "./journals-settings.scss";
import { SettingsTitleModule } from "../settings-title-module/settings-title-module";
import { SingleTableModule } from "../single-table-module";

type mockDataType = {
  key: number;
  title: string;
  rows: {
    name: string;
    organization: string;
    position: string;
    email: string;
    phone: string;
    id: number;
  }[];
}[];

const data: mockDataType = [
  {
    key: 1,
    title: "Заполнение данных",
    rows: [
      {
        name: "Харин Дмитрий",
        organization: "ООО “Интэнт”",
        position: "Дизайнер",
        email: "dima@builddocs.ru",
        phone: "+7 (921) 334-4000",
        id: 1,
      },
    ],
  },
  {
    key: 2,
    title: "Проверка данных",
    rows: [
      {
        name: "Харин Дмитрий",
        organization: "ООО “Интэнт”",
        position: "Дизайнер",
        email: "dima@builddocs.ru",
        phone: "+7 (921) 334-4000",
        id: 1,
      },
    ],
  },
  {
    key: 3,
    title: "Отправка в ГСН",
    rows: [
      {
        name: "Харин Дмитрий",
        organization: "ООО “Интэнт”",
        position: "Дизайнер",
        email: "dima@builddocs.ru",
        phone: "+7 (921) 334-4000",
        id: 1,
      },
    ],
  },
];

export const JournalsSettings = () => {
  return (
    <div>
      <SingleTableModule />
      <SettingsTitleModule />
    </div>
  );
};
