import { Fragment, ReactNode } from "react";
import { Row, Col, Button, Typography } from "antd";
import "./table-info.scss";
import { TableInfoType } from "../types";

const { Title } = Typography;

interface ITableInfo {
  tabs: TableInfoType[];
  name?: string;
  isButton?: boolean;
  buttonName?: string;
  handleIsButton?: () => void;
  children?: ReactNode;
  id?: string;
}

export const TableInfo = ({
  tabs,
  name,
  isButton,
  buttonName,
  handleIsButton,
  children,
  id,
}: ITableInfo) => {
  return (
    <div className="table-container" id={id ? id : ""}>
      {name ? (
        <div className="flex-between">
          <Title
            style={{
              marginBottom: "24px",
            }}
            level={4}
          >
            {name}
          </Title>

          {isButton && handleIsButton ? (
            <Button type="default" style={{ backgroundColor: "#EAEEF8" }}>
              {buttonName ? buttonName : "Редактировать"}
            </Button>
          ) : null}
        </div>
      ) : null}
      <Row gutter={[16, 16]}>
        {children}
        {tabs.map(({ id, title, info }) => (
          <Fragment key={id}>
            <Col span={8} style={{ color: "#888888", lineHeight: "20px" }}>
              {title}
            </Col>
            <Col
              span={16}
              className="table-info-content"
              style={{ lineHeight: "20px" }}
            >
              {info}
            </Col>
          </Fragment>
        ))}
      </Row>
    </div>
  );
};
