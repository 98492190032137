import { TableInfo, TableInfoType } from "@/features/table-info";
import { Col, Input } from "antd";
import { Fragment } from "react/jsx-runtime";

const tableMock: TableInfoType[] = [
  {
    id: 1,
    title: "Вид строительства",
    info: "Строительство",
  },
  {
    id: 2,
    title: "Наименование объекта капитального строительства",
    info: "Жилой двухэтажный каркасный дом синего цвета с блестящей трубой снаружи",
  },
  {
    id: 3,
    title: "Строительный адрес объекта",
    info: "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная уч. 40",
  },
];

export const JournalsInfoTable = () => {
  return (
    <TableInfo
      tabs={tableMock}
      name="Информация об объекте"
      id="journals-info-table"
    >
      <Fragment>
        <Col span={8} style={{ color: "#666666" }}>
          Номер журнала
        </Col>
        <Col span={16}>
          <Input style={{ maxWidth: "104px" }} defaultValue={1} />
        </Col>
      </Fragment>
    </TableInfo>
  );
};
