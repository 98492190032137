import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { JournalsNotes } from "./ui";

export const JournalsNotesRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.journals.notes()}`,
    element: createElement(JournalsNotes),
    children: [...rest],
  };
};
