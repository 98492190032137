import { Spin, Typography } from "antd";
import { Outlet } from "react-router-dom";
import "./project-objects-view.scss";
import { Breadcrumbs } from "@/features/breadcrumb";
import { ObjectTabs } from "@/features/object-tabs";
import { routes } from "@/shared/lib/react-router";
import { useObject } from "@entities/object/";
import { useCoordinator } from "@shared/lib/hooks";

const { Title } = Typography;

const routesMock = [
  { path: "", label: "Проекты" },
  { path: routes.project.root({ projectId: "312" }), label: "Загородный дом" },
  { path: routes.objects.root(), label: "Объекты" },
];

export const ProjectObjectsView = () => {
  const { objectId } = useCoordinator() as { objectId: string };
  const { data: object, isLoading } = useObject({ objectId });
  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50%",
          minWidth: "50%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  return (
    <div className="general-view-container">
      <Breadcrumbs routes={routesMock} />
      <Title level={3}>{object.shortName}</Title>
      <ObjectTabs />
      <Outlet />
    </div>
  );
};
