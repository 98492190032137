import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { ProjectObjectsView } from "./ui/project-objects-view.tsx";

export const createProjectObjectsView = (
  ...rest: RouteObject[]
): RouteObject => {
  return {
    path: `${routes.objects.root()}`,
    element: createElement(ProjectObjectsView),
    children: [...rest],
  };
};
