import { Tooltip } from "antd";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import "./navigation-project.scss";

export const NavigationProject = ({
  collapsed,
  projectId,
  projectName,
  projectDescription,
}: {
  collapsed: boolean;
  projectId: string;
  projectName: string;
  projectDescription: string;
}) => {
  return (
    <div className="navigation-project-block">
      <motion.div
        animate={{
          visibility: collapsed ? "hidden" : "visible",
          opacity: collapsed ? 0 : 1,
        }}
      >
        <p className="navigation-project-label">ПРОЕКТ</p>
        <span>
          <Link
            className="navigation-project-address"
            to={routes.general.root(projectId)}
          >
            {projectName}
          </Link>
          <span className="navigation-project-address">
            {projectDescription}
          </span>
        </span>
      </motion.div>
      <motion.div
        className={`navigation-project-collapsed-icon ${collapsed ? "left-sider-project-menu-collapsed" : ""}`}
        initial={{ visibility: "hidden" }}
        animate={{
          visibility: collapsed ? "visible" : "hidden",
          opacity: collapsed ? 1 : 0,
        }}
        transition={{ duration: 0.5 }}
      >
        <Tooltip
          placement="right"
          title={collapsed ? "главная проекта" : undefined}
        >
          <Link className="menu-item-link" to="#">
            <motion.svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.75 3.25V22C4.75 22.4142 4.41421 22.75 4 22.75C3.58579 22.75 3.25 22.4142 3.25 22V3C3.25 2.30965 3.80965 1.75 4.5 1.75H14.5C15.1904 1.75 15.75 2.30966 15.75 3V22C15.75 22.4142 15.4142 22.75 15 22.75C14.5858 22.75 14.25 22.4142 14.25 22V3.25H4.75Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.357 7.11427C14.5701 6.75908 15.0308 6.64388 15.386 6.85697L20.386 9.85662C20.6119 9.99216 20.7501 10.2363 20.7501 10.4998V22.0001C20.7501 22.4143 20.4143 22.7501 20.0001 22.7501C19.5859 22.7501 19.2501 22.4143 19.2501 22.0001V10.9244L14.6143 8.14325C14.2591 7.93016 14.1439 7.46947 14.357 7.11427Z"
                fill="#333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 22C1.25 21.5858 1.58579 21.25 2 21.25H22C22.4142 21.25 22.75 21.5858 22.75 22C22.75 22.4142 22.4142 22.75 22 22.75H2C1.58579 22.75 1.25 22.4142 1.25 22Z"
                fill="#333333"
              />
            </motion.svg>
          </Link>
        </Tooltip>
      </motion.div>
    </div>
  );
};
