import { Table, Typography } from "antd";
import { Link } from "react-router-dom";
import "./object-info-opinion.scss";
import { DocumentatuionExaminations } from "@shared/api/object/types.ts";

const { Title } = Typography;

const opinionsData = [
  {
    key: 1,
    number: "001-001",
    date: "11.11.2023",
    issued:
      "Комитет по положительным заключениям экспертизы проектной документации",
  },
  {
    key: 2,
    number: "001-002",
    date: "31.12.2023",
    issued:
      "Комитет по положительным заключениям экспертизы проектной документации",
  },
];

const columns = [
  {
    title: "№ заключения",
    dataIndex: "number",
    key: "number",
    render: (text: string) => <Link to="#">{text}</Link>,
  },
  {
    title: "Дата выдачи",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Наименование органа, выдавшего заключение",
    dataIndex: "issued",
    key: "issued",
    render: (text: string) => (
      <div style={{ maxWidth: "490px", minWidth: "490px" }}>{text}</div>
    ),
  },
];

export const ObjectInfoOpinion = ({
  data,
}: {
  data: DocumentatuionExaminations[];
}) => {
  // TODO: data - неполная, удалить buildDate updateDate
  return (
    <div className="table-container">
      <Title level={5}>
        Сведения о положительном заключении экспертизы проектной документации
      </Title>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        className="custom-table"
        scroll={{ x: true }}
      />
    </div>
  );
};
