import React, { useState } from "react";
import {
  Typography,
  Select,
  Button,
  Row,
  Col,
  Divider,
  Dropdown,
  Menu,
  Input,
} from "antd";
import { BasketIcon, MoreOneIcon, EditIcon } from "@/shared/assets/svg/icons";
import { useDisclosure } from "@/shared/lib/hooks";
import { RepresentationMoodal } from "@/features/representation-modal";

import "./journals-other-table.scss";

type Organization = {
  id: number;
  name: string;
  inn: string;
  activity: string;
  hasEmployee?: boolean;
};

type EmployeeData = {
  key: number;
  title: string;
  info: string;
};

const mockOrganizations: Organization[] = [
  {
    id: 1,
    name: "Строительная компания",
    inn: "1234567890",
    activity: "Монтажные работы",
  },
  {
    id: 2,
    name: "Проектная организация",
    inn: "0987654321",
    activity: "Проектирование",
  },
  {
    id: 3,
    name: "Архитектурное бюро",
    inn: "5678901234",
    activity: "Архитектурное проектирование",
  },
];

const employeeMockData: EmployeeData[] = [
  { key: 1, title: "Организация", info: "ООО “Проектировщик”" },
  { key: 2, title: "Должность", info: "Проектировщик" },
  {
    key: 3,
    title: "Документ, №, дата",
    info: "Приказ 66, 12.10.2021, подпись.sig",
  },
  { key: 4, title: "Почта", info: "enadzor2000@mail.ru" },
  { key: 5, title: "Номер в НОПРИЗ", info: "-" },
  { key: 6, title: "Телефон", info: "8 (921) 123-3210" },
];

export const JournalsOtherTable: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAddEmployee = () => {
    setOrganizations((prev) =>
      prev.map((org) =>
        org.id === selectedOrgId ? { ...org, hasEmployee: true } : org,
      ),
    );
    setSelectedOrgId(null);
    onClose();
  };

  const handleAddOrganization = (orgId: number) => {
    const selectedOrg = mockOrganizations.find((org) => org.id === orgId);
    if (selectedOrg) {
      setOrganizations((prev) => [
        ...prev,
        { ...selectedOrg, hasEmployee: false },
      ]);
    }
  };

  const handleEditEmployee = () => {
    onOpen();
  };

  const handleDeleteEmployee = (orgId: number) => {
    setOrganizations((prev) =>
      prev.map((org) =>
        org.id === orgId ? { ...org, hasEmployee: false } : org,
      ),
    );
  };

  return (
    <div className="table-container" id="journals-other-table">
      <Typography.Title level={4}>
        Другие лица, осуществляющие строительство, их уполномоченные
        представители
      </Typography.Title>
      <div style={{ marginTop: "14px" }}>
        {organizations.map((org) => (
          <div key={org.id} style={{ marginBottom: "24px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography.Title level={5}>{org.name}</Typography.Title>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="delete"
                      icon={<BasketIcon />}
                      onClick={() =>
                        setOrganizations((prev) =>
                          prev.filter((item) => item.id !== org.id),
                        )
                      }
                    >
                      Удалить
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <MoreOneIcon
                  style={{ cursor: "pointer" }}
                  width={24}
                  height={24}
                />
              </Dropdown>
            </div>
            <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
              <Col span={8} style={{ color: "#888888" }}>
                ИНН
              </Col>
              <Col span={16}>{org.inn}</Col>
              <Col span={8} style={{ color: "#888888" }}>
                Выполняемые работы
              </Col>
              <Col span={16}>{org.activity}</Col>
            </Row>
            {org.hasEmployee ? (
              <div style={{ marginTop: "16px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography.Title level={5} style={{ fontWeight: "400" }}>
                    Уполномоченный представитель
                  </Typography.Title>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="edit"
                          icon={<EditIcon />}
                          onClick={handleEditEmployee}
                        >
                          Редактировать
                        </Menu.Item>
                        <Menu.Item
                          key="delete"
                          icon={<BasketIcon />}
                          onClick={() => handleDeleteEmployee(org.id)}
                        >
                          Удалить
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <MoreOneIcon
                      style={{ cursor: "pointer" }}
                      width={24}
                      height={24}
                    />
                  </Dropdown>
                </div>
                <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                  {employeeMockData.map((item) => (
                    <React.Fragment key={item.key}>
                      <Col span={8} style={{ color: "#888888" }}>
                        {item.title}
                      </Col>
                      <Col span={16}>{item.info}</Col>
                    </React.Fragment>
                  ))}
                </Row>
              </div>
            ) : (
              <Button
                type="primary"
                style={{ marginTop: "16px" }}
                onClick={() => {
                  setSelectedOrgId(org.id);
                  onOpen();
                }}
              >
                Добавить представителя
              </Button>
            )}
          </div>
        ))}
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "16px",
          }}
        >
          <Typography.Text style={{ marginBottom: "4px" }}>
            Организация
          </Typography.Text>
          <Select
            placeholder="Выберите организацию"
            style={{ width: "50%", marginBottom: "16px" }}
            onChange={handleAddOrganization}
          >
            {mockOrganizations.map((org) => (
              <Select.Option key={org.id} value={org.id}>
                {org.name}
              </Select.Option>
            ))}
          </Select>
          <Input
            placeholder="Введите выполняемые работы"
            style={{ width: "50%" }}
          />
        </div>
      </div>
      <RepresentationMoodal
        isRepresentation={isOpen}
        handleClose={onClose}
        handleAdd={handleAddEmployee}
      />
    </div>
  );
};
