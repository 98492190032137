import { ReactNode, useRef } from "react";
import { PureAbility } from "@casl/ability";
import {
  AbilityContext,
  AppAbility,
} from "@shared/lib/casl/ability-context.tsx";

interface CaslProviderProps {
  children: ReactNode;
}

export function CaslProvider({ children }: CaslProviderProps) {
  const initialAbility = useRef<AppAbility>(new PureAbility());
  return (
    <AbilityContext.Provider value={initialAbility.current}>
      {children}
    </AbilityContext.Provider>
  );
}
