import { Breadcrumb } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";

type Route = {
  path: string;
  label: string;
};

interface BreadcrumbsProps {
  routes: Route[];
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ routes }) => {
  return (
    <Breadcrumb separator=" / " style={{ marginBottom: "17px" }}>
      {routes.map((route, index) => (
        <Breadcrumb.Item key={index}>
          <Link to={route.path}>{route.label}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
