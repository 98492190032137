import { Button } from "antd";
import dog_project from "@shared/assets/png/dock-project.png";

import "./project-empty.scss";

export const Empty = () => {
  return (
    <main className="no-projects-layout">
      <div className="no-projects-content">
        <img
          src={dog_project as string}
          alt="No Projects"
          className="no-projects-image"
        />
        <h2>Проекты не созданы</h2>
        <Button
          type="primary"
          size="large"
          className="project-main-button-create"
        >
          Создать проект
        </Button>
      </div>
    </main>
  );
};
