import { FC, useState } from "react";
import { Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  CommentIcon,
  FootballIcon,
  VectorIcon,
} from "@/shared/assets/svg/icons";
import "./journals-expandable-table.scss";
import { Link } from "react-router-dom";

interface DataType {
  key: string;
  name: string;
  role: string;
  position: string;
  email: string;
  phone: string;
  isBall?: boolean;
  isComment?: boolean;
}

const data: DataType[] = [
  {
    key: "1",
    name: "Харин Дмитрий",
    role: "Администратор",
    position: "Дизайнер",
    email: "dima@builddocs.ru",
    phone: "+7 (921) 334-4000",
  },
  {
    key: "2",
    name: "Харин Дмитрий",
    isBall: true,
    role: "Заполнение данных",
    position: "Дизайнер",
    email: "dima@builddocs.ru",
    phone: "+7 (921) 334-4000",
    isComment: true,
  },
  {
    key: "3",
    name: "Харин Дмитрий",
    role: "Проверка данных",
    position: "Дизайнер",
    email: "dima@builddocs.ru",
    phone: "+7 (921) 334-4000",
  },
  {
    key: "4",
    name: "Харин Дмитрий",
    role: "Отправка в ГСН",
    position: "Дизайнер",
    email: "dima@builddocs.ru",
    phone: "+7 (921) 334-4000",
  },
];

export const JournalsExpandableTable: FC = () => {
  const [expanded, setExpanded] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: "ФИО",
      dataIndex: "name",
      key: "name",
      render: (text, reacord) => (
        <div className="journal-expanded-name">
          <Link to="/">{text}</Link>
          {reacord.isBall ? (
            <FootballIcon style={{ marginLeft: "4px" }} />
          ) : null}
        </div>
      ),
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Должность",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (text, reacord) => (
        <div className="journal-expanded-name">
          <div>{text}</div>
          {reacord.isComment ? (
            <Tooltip title="Какое-то сообщение от отвественного лица">
              <CommentIcon style={{ marginLeft: "16px", cursor: "pointer" }} />
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div
      className="table-container table-container-scroll-tab"
      id="journals-documentation-table"
    >
      <div onClick={() => setExpanded(!expanded)} className="table-header">
        <span
          style={expanded ? { marginBottom: 12 } : {}}
          className="expandable-icon-container"
        >
          <div className={`expandable-icon ${expanded ? "open" : ""}`}>
            <VectorIcon />
          </div>
          <div>Ответственные лица</div>
        </span>
      </div>
      <div
        className={`table-body ${expanded ? "open" : "closed"} table-journal`}
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          rowClassName={({ key }) => (key === "2" ? "table-row-grey" : "")}
        />
      </div>
    </div>
  );
};
