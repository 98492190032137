import { useState } from "react";
import { Layout } from "antd";
import { NavigationContracts } from "./navigation-contracts";
import { NavigationMenu } from "./navigation-menu";
import { NavigationProject } from "./navigation-project";
import "./navigation.scss";
import { useCoordinator } from "@shared/lib/hooks";

const { Sider } = Layout;

const Navigation = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { projectId } = useCoordinator();
  return (
    <Sider
      width={240}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <NavigationProject
        projectId={projectId || ""}
        collapsed={collapsed}
        projectName="Загородный дом"
        projectDescription="Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная уч. 40"
      />
      <NavigationMenu projectId={projectId || ""} collapsed={collapsed} />
      <NavigationContracts />
    </Sider>
  );
};

export default Navigation;
