import { Fragment, useState } from "react";
import {
  Col,
  Dropdown,
  Menu,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Typography,
} from "antd";
import { BasketIcon, CopyIcon, MoreOneIcon } from "@/shared/assets/svg/icons";
import { tableIntelligenceMock, tableIntelligenceMockType } from "./tableMock";
import { journalInfoType } from "@/app/composition/views/project-journals/ui/project-journals";

const { Title } = Typography;

enum OrgEnum {
  builder = "builder",
  exploitationOrg = "exploitationOrg",
  regionalOrg = "regionalOrg",
}

interface IJournalsIntelligenceTable {
  journalInfo: journalInfoType;
}

export const JournalsIntelligenceTable = ({
  journalInfo,
}: IJournalsIntelligenceTable) => {
  const [typeOrg, setTypeOrg] = useState<OrgEnum>(OrgEnum.builder);
  const [selectedOrg, setSelectedOrg] =
    useState<tableIntelligenceMockType | null>(null);

  const handleChangeType = (e: RadioChangeEvent) => {
    setTypeOrg(e.target.value);
  };

  const handleChangeOrg = (key: number) => {
    const foundOrg = tableIntelligenceMock.find((item) => item.key === key);
    setSelectedOrg(foundOrg || null);
  };

  const handleDelete = () => {
    setSelectedOrg(null);
    setTypeOrg(OrgEnum.builder);
  };

  const menu = (
    <Menu>
      <Menu.Item key="delete" icon={<BasketIcon />} onClick={handleDelete}>
        Удалить
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="table-container">
      <Title level={4} style={{ marginBottom: "24px" }}>
        Сведения о застройщике, эксплуатирующей организации или региональном
        операторе
      </Title>
      {selectedOrg && !journalInfo.isHideTableInfo ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginBottom: "16px", fontSize: "16px" }}>
              ООО &quot;{selectedOrg.name}&quot;
            </div>
            <Dropdown overlay={menu} trigger={["click"]}>
              <MoreOneIcon
                style={{ cursor: "pointer" }}
                width={24}
                height={24}
              />
            </Dropdown>
          </div>
          <Row gutter={[16, 16]}>
            {selectedOrg.table.map(({ id, title, info }) => (
              <Fragment key={id}>
                <Col span={8} style={{ color: "#888888" }}>
                  {title}
                </Col>
                <Col span={16} className="table-info-content">
                  {info}
                </Col>
              </Fragment>
            ))}
          </Row>
        </>
      ) : (
        <>
          {!journalInfo.isHideButtons ? (
            <>
              <div>Тип организации</div>
              <Radio.Group
                onChange={handleChangeType}
                value={typeOrg}
                style={{ marginTop: "8px" }}
              >
                <Radio value={OrgEnum.builder}>Застройщик</Radio>
                <Radio value={OrgEnum.exploitationOrg}>
                  Эксплуатирующая организация
                </Radio>
                <Radio value={OrgEnum.regionalOrg}>Региональный оператор</Radio>
              </Radio.Group>
              <div style={{ marginTop: "24px" }}>
                <div style={{ marginBottom: "4px" }}>Организация</div>
                <Select
                  placeholder="Введите название или ИНН"
                  onChange={handleChangeOrg}
                >
                  {tableIntelligenceMock.map((item) => {
                    return (
                      <Select.Option key={item.key} value={item.key}>
                        <div>OOO &quot;{item.name}&quot;</div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            color: "#888888",
                          }}
                        >
                          <div>ИНН &quot;{item.table[1].info}&quot;</div>
                          <div>КПП &quot;{item.table[2].info}&quot;</div>
                          <div>ОГРН &quot;{item.table[3].info}&quot;</div>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};
