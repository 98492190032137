import { TableInfo, TableInfoType } from "@/features/table-info";
import "./journals-state-supervision.scss";

const tableMock: TableInfoType[] = [
  {
    id: 1,
    title: "Наименование",
    info: "Застройщик",
  },
  {
    id: 2,
    title: "ИНН",
    info: "1234141000",
  },
  {
    id: 3,
    title: "ОГРН",
    info: "783901001",
  },
  {
    id: 4,
    title: "Адрес",
    info: "190020, г. Санкт-Петербург, вн.тер.г. Муниципальный Округ Екатерингофский, наб Обводного Канала, д. 138, к. 1, литера А, офис 432, пом. 7Н-10",
  },
  {
    id: 5,
    title: "Телефон",
    info: "+7 (800) 123-7777",
  },
  {
    id: 6,
    title: "E-mail",
    info: "mail@domain.ru",
  },
];

export const JournalsStateSupervision = () => {
  return (
    <TableInfo
      tabs={tableMock}
      name="Сведения об органе государственного строительного надзора"
      id="journals-state-supervision"
    />
  );
};
