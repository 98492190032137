import {
  // CopyIcon,
  BasketIcon,
  CopyPdfIcon,
  MoreOneIcon,
  // CopyPdfIcon,
  // SuccessIcon,
} from "@/shared/assets/svg/icons";
import { useDisclosure } from "@/shared/lib/hooks";
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Radio,
  Row,
  Table,
  Typography,
} from "antd";
import Modal from "antd/es/modal/Modal";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const tableMock = [
  {
    id: 1,
    title: "Дата выдачи",
    info: "11.11.2023",
  },
  {
    id: 2,
    title: "Наименование органа, выдавшего разрешение",
    info: "Комитет по комитетам строительных разрешений",
  },
  {
    id: 3,
    title: "Срок действия",
    info: "11.11.2023",
  },
  {
    id: 4,
    title: "Продлён до",
    info: "-",
  },
];

const dataSource = [
  {
    key: "1",
    number: "55/11-123",
    date: "11.07.2024",
    comment: "Комитет по экспертизе разрешений документации",
  },
  {
    key: "2",
    number: "55/11-123",
    date: "11.07.2024",
    comment: "Комитет по экспертизе разрешений документации",
  },
  {
    key: "3",
    number: "55/11-123",
    date: "11.07.2024",
    comment: "Комитет по экспертизе разрешений документации",
  },
];
export const JournalsPermissionTable = () => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [selectedRow, setSelectedRow] = useState<{
    key: string;
    number: string;
    date: string;
    comment: string;
  } | null>(null);
  const [selectedRowPrev, setSelectedRowPrev] = useState<{
    key: string;
    number: string;
    date: string;
    comment: string;
  } | null>(null);
  const handleModalOpen = () => {
    onOpen();
  };
  const handleModalClose = () => {
    onClose();
  };
  const handleSelectRow = (
    key: {
      key: string;
      number: string;
      date: string;
      comment: string;
    } | null,
  ) => {
    setSelectedRowPrev(key);
  };
  const handleAdd = () => {
    setSelectedRow(selectedRowPrev);
    onClose();
  };
  const handleDelete = () => {
    setSelectedRow(null);
  };
  const columns = [
    {
      title: "№ заключения",
      dataIndex: "number",
      key: "number",
      render: (
        text: string,
        record: {
          key: string;
          number: string;
          date: string;
          comment: string;
        },
      ) => (
        <Radio
          checked={selectedRowPrev?.key === record.key}
          onChange={() => handleSelectRow(record)}
        >
          {text}
        </Radio>
      ),
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
    },
  ];
  const menu = (
    <Menu>
      <Menu.Item key="delete" icon={<BasketIcon />} onClick={handleDelete}>
        Удалить
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="table-container" id="journals-permission-table">
      <Typography.Title level={4}>Разрешение на строительство</Typography.Title>
      <Modal
        title="Выберите разрешение на строительство"
        open={isOpen}
        onCancel={handleModalClose}
        footer={null}
        centered
        width={800}
      >
        <div className="table-container">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered
          />
        </div>
        <Divider style={{ marginBottom: "16px" }} />
        <Button type="primary" onClick={handleAdd}>
          Подтвердить выбор
        </Button>
      </Modal>
      {selectedRow ? (
        <div role="button" tabIndex={0}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title
              level={5}
              style={{ fontWeight: "400", marginTop: "24px" }}
            >
              Заключение № 55/11-123
            </Typography.Title>
            <Dropdown overlay={menu} trigger={["click"]}>
              <MoreOneIcon
                style={{ cursor: "pointer" }}
                width={24}
                height={24}
              />
            </Dropdown>
          </div>

          <Row
            gutter={[16, 16]}
            style={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {tableMock.map(({ id, title, info }) => (
              <Fragment key={id}>
                <Col span={8} style={{ color: "#888888" }}>
                  {title}
                </Col>
                <Col span={16} className="table-info-content">
                  {info}
                </Col>
              </Fragment>
            ))}
          </Row>
        </div>
      ) : null}

      {selectedRow ? null : (
        <Button
          type="primary"
          style={{ marginTop: "14px" }}
          onClick={handleModalOpen}
        >
          Выбрать
        </Button>
      )}
    </div>
  );
};
