import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "@/shared/lib/react-router";
import "./project-tabs.scss";
import { useCoordinator } from "@shared/lib/hooks/";

const getTabs = (projectId: string) => [
  { key: routes.general.root(projectId), label: "Общая информация" },
  { key: routes.general.members(projectId), label: "Участники" },
  {
    key: routes.objects.root(projectId),
    label: "Объекты строительства",
  },
  { key: routes.general.related(projectId), label: "Связанные данные" },
  { key: routes.general.settings(projectId), label: "Настройки" },
  { key: routes.general.history(projectId), label: "История" },
];

export const ProjectTabs = () => {
  const navigate = useNavigate();
  const { projectId } = useCoordinator();
  const location = useLocation();
  const tabs = getTabs(projectId || "");

  const defaultActiveKey = tabs.reduce((bestMatch, tab) => {
    return location.pathname.startsWith(tab.key) &&
      tab.key.length > (bestMatch?.length || 0)
      ? tab.key
      : bestMatch;
  }, "");

  const onTabChange = (key: string) => {
    navigate(key);
  };
  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      items={tabs}
      className="project-custom-tabs"
      onChange={onTabChange}
      tabBarStyle={{
        overflow: "hidden",
      }}
      tabBarGutter={32}
      style={{ marginBottom: "32px" }}
    />
  );
};
