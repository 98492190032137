import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { Project } from "@entities/project";
import { useProjectImage } from "@entities/project/queries/use-project-image.ts";
import { ProjectDetails } from "@features/project-details";
import LocalIcon from "@shared/assets/png/local-icon.png";
import { PenIcon } from "@shared/assets/svg/icons";
import { routes } from "@shared/lib/react-router";

const contractors = [
  'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
  'ООО "ПОДРЯДЧИК"',
];

interface ProjectCardProps {
  project: Project;
}

export const ProjectCard = ({ project }: ProjectCardProps) => {
  const navigate = useNavigate();

  const { isModern } = project;

  const { data: image } = useProjectImage({
    projectId: project.id,
    imageId: project.avatar,
    enabled: isModern && !!project.avatar,
  });
  const imageSource = isModern
    ? image?.content
    : project.avatar && `data:image/png;base64,${project.avatar}`;

  const handleNavigate = (project: Project) => {
    navigate(`${routes.general.root(String(`${project.id}`))}`);
  };

  const handleIconClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  return (
    <Card
      cover={
        <div className="project-image-container">
          {imageSource ? (
            <img src={imageSource} alt={project.shortName} />
          ) : (
            <div className="image-placeholder" />
          )}
          <div className="project-image-edit" onClick={handleIconClick}>
            <PenIcon width={12} height={12} />
          </div>
        </div>
      }
      className="project-card"
      onClick={() => handleNavigate(project)}
    >
      <Meta
        title={project.shortName ?? "Проект"}
        className="project-meta-title"
      />
      {/* Ни у одного DTO нет этих цифр */}
      <ProjectDetails tasks={0} files={0} folders={0} />
      <Meta
        description={project.fullName}
        style={{
          marginTop: "16px",
          fontSize: "12px",
          color: "#888888",
          fontWeight: "400",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <img
          src={LocalIcon}
          alt="LocalIcon"
          width={16}
          height={16}
          style={{ marginRight: "8px", height: "auto" }}
        />
        <div className="project-location">{project.address}</div>
      </div>

      {contractors?.map((contractor) => (
        <div className="project-contractors" key={contractor}>
          <img
            src={LocalIcon}
            alt="LocalIcon"
            width={16}
            height={16}
            style={{ marginRight: "8px", height: "auto" }}
          />
          <div>{contractor}</div>
        </div>
      ))}
    </Card>
  );
};
