import { useState } from "react";
import "./journals-history.scss";
import { ProjectRelatedTabs } from "@/features/project-related-tabs";
import { JournalsSettingsIntegration } from "../ journals-settings-integration";
import { JournalsSettingsTitle } from "../journals-settings-title";
const tabRoutes: Record<string, string> = {
  "История титульного листа": "title",
  "История интеграции с ГСН": "integration",
};
const tabs = Object.keys(tabRoutes);

export const JournalsHistory = () => {
  const getActiveTab = () => {
    const currentRoute = location.pathname.split("/").pop();
    const activeTab = Object.entries(tabRoutes).find(
      ([, route]) => route === currentRoute,
    );
    return activeTab ? activeTab[0] : "История титульного листа";
  };
  const [activeTab, setActiveTab] = useState(getActiveTab());

  return (
    <div>
      <ProjectRelatedTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === "История титульного листа" ? (
        <JournalsSettingsTitle />
      ) : (
        <JournalsSettingsIntegration />
      )}
    </div>
  );
};
