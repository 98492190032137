import { Flex, Input, Typography } from "antd";
import type { DaDataParty, DaDataSuggestion } from "react-dadata/dist/types";
import { FormItem } from "react-hook-form-antd";
import { InnInput } from "@features/inn-input";
import { env } from "@shared/config.ts";

const CustomerBlock = ({
  methods,
  representative,
}: {
  methods: any;
  representative: "customer" | "developer";
}) => {
  const addCustomerDadata = (info: DaDataSuggestion<DaDataParty>) => {
    console.log(info);
    const organization = info.data;
    const configuredData = {
      inn: organization.inn,
      kpp: organization.kpp,
      name: info.value,
      ogrn: organization.ogrn,
      address: organization.address.value,
      phone: organization?.phones,
      sro: "",
      sroDate: "",
    };
    return methods.setValue(representative, configuredData);
  };
  return (
    <Flex style={{ marginBottom: 0 }} vertical gap={8}>
      <Typography.Title level={5}>
        {representative === "customer"
          ? "Информация о ген. подрядчике"
          : "Информация о заказчике"}
      </Typography.Title>
      {/* @ts-ignore */}
      <InnInput token={env.DADATA_API_KEY} onSelect={addCustomerDadata} />
      <FormItem
        style={{ marginBottom: 0 }}
        control={methods.control}
        name={`${representative}.name`}
      >
        <Input placeholder="Наименование организации" />
      </FormItem>
      <FormItem
        style={{ marginBottom: 0 }}
        control={methods.control}
        name={`${representative}.address`}
      >
        <Input placeholder="Почтовые реквизиты" />
      </FormItem>

      <Flex gap={8}>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.inn`}
        >
          <Input placeholder="Инн" />
        </FormItem>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.ogrn`}
        >
          <Input placeholder="Огрн" />
        </FormItem>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.sro`}
        >
          <Input placeholder="СРО" />
        </FormItem>
      </Flex>

      <Flex gap={8}>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.kpp`}
        >
          <Input placeholder="КПП" />
        </FormItem>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.ogrnDate`}
        >
          <Input placeholder="Дата выдачи ОГРН" />
        </FormItem>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.sroDate`}
        >
          <Input placeholder="Дата СРО" />
        </FormItem>
      </Flex>
      <FormItem control={methods.control} name={`${representative}.phone`}>
        <Input placeholder="Телефон" />
      </FormItem>
    </Flex>
  );
};

export default CustomerBlock;
