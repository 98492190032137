import React, { useState } from "react";
import { Typography, Button, Row, Col, Tooltip } from "antd";
import { RepresentationMoodal } from "@/features/representation-modal";
import { BasketIcon } from "@/shared/assets/svg/icons";
import "./single-table-module.scss";

type User = {
  id: number;
  name: string;
  organization: string;
  position: string;
  email: string;
  phone: string;
};

const mockUser = (id: number): User => ({
  id,
  name: `Пользователь ${id}`,
  organization: "ООО “Интент”",
  position: "Дизайнер",
  email: `user${id}@example.com`,
  phone: `+7 (921) 334-400${id}`,
});

export const SingleTableModule: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleAddUser = () => {
    setUsers((prevUsers) => [...prevUsers, mockUser(prevUsers.length + 1)]);
    setIsOpen(false);
  };

  const handleDeleteUser = (userId: number) => {
    setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="table-container single-table-module">
      <div className="flex-between" style={{ marginBottom: "24px" }}>
        <Typography.Title level={4}>Администратор журнала</Typography.Title>
        {users.length > 0 && (
          <Button type="link" onClick={handleOpenModal}>
            Добавить
          </Button>
        )}
      </div>

      {users.length > 0 && (
        <>
          <Row className="table-header" gutter={[16, 16]}>
            <Col span={5}>
              <Typography.Text style={{ color: "#666666" }}>
                ФИО
              </Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text style={{ color: "#666666" }}>
                Организация
              </Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text style={{ color: "#666666" }}>
                Должность
              </Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text style={{ color: "#666666" }}>
                Email
              </Typography.Text>
            </Col>
            <Col span={4}>
              <Typography.Text style={{ color: "#666666" }}>
                Телефон
              </Typography.Text>
            </Col>
          </Row>
          {users.map((user) => (
            <Row
              key={user.id}
              className="user-row"
              gutter={[16, 16]}
              align="middle"
            >
              <Col span={5}>{user.name}</Col>
              <Col span={5}>{user.organization}</Col>
              <Col span={5}>{user.position}</Col>
              <Col span={5}>{user.email}</Col>
              <Col span={3}>{user.phone}</Col>
              <Col span={1} style={{ textAlign: "right" }}>
                <Button
                  type="text"
                  icon={
                    <Tooltip title="Удалить сотрудника">
                      <div className="basket-container">
                        <BasketIcon />
                      </div>
                    </Tooltip>
                  }
                  onClick={() => handleDeleteUser(user.id)}
                />
              </Col>
            </Row>
          ))}
        </>
      )}

      {users.length === 0 && (
        <Button
          type="primary"
          className="add-user-button"
          onClick={handleOpenModal}
          style={{ marginTop: "16px" }}
        >
          Добавить сотрудника
        </Button>
      )}

      <RepresentationMoodal
        isRepresentation={isOpen}
        handleClose={handleCloseModal}
        handleAdd={handleAddUser}
        type="settings"
      />
    </div>
  );
};
