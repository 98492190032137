import { Row, Col, Button } from "antd";
import { SettingsCardItem } from "../settings-card-item/settings-card-item";

interface ISettingsCardBlock {
  onAddClick?: boolean;
  cards: {
    id: number;
    title: string;
    description: string;
    author: string;
    date: string;
    isActive?: boolean;
  }[];
  blockTitle: string;
}

export const SettingsCardBlock = ({
  onAddClick,
  cards,
  blockTitle,
}: ISettingsCardBlock) => {
  return (
    <div className="project-setting-container">
      <div className="project-setting-header">
        <h2 className="project-setting-title">{blockTitle}</h2>
        {onAddClick && <Button type="link">Добавить</Button>}
      </div>
      <Row gutter={[16, 16]}>
        {cards.map((card) => (
          <Col
            key={card.id}
            xs={24}
            sm={12}
            md={8}
            className="project-setting-card-col"
          >
            <SettingsCardItem {...card} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
