import { Dropdown, Menu, Col, Row, Typography, Select } from "antd";
import {
  BasketIcon,
  MoreOneIcon,
  SuccessIcon,
  EditIcon,
} from "@/shared/assets/svg/icons";
import { Fragment, useState } from "react";

const tableMock = [
  {
    id: 1,
    title: "ИНН",
    info: "7839136848",
  },
  {
    id: 2,
    title: "КПП",
    info: "783901001",
  },
  {
    id: 3,
    title: "ОГРН",
    info: "1217800024265",
  },
  {
    id: 4,
    title: "Контакты",
    info: "8 (800) 123-7777",
    isEmail: true,
  },
  {
    id: 5,
    title: "Адрес",
    info: "190020, г. Санкт-Петербург, пр. Невский, д. 111, к. 1",
  },
];

const organizations = [
  { id: 1, name: "ООО Генподрядчик" },
  { id: 2, name: "ООО Субподрядчик" },
  { id: 3, name: "ООО Подрядчик Строительство" },
];

export const JournalsConstructionTable = () => {
  const [selectedOrg, setSelectedOrg] = useState<number | null>(null); // ID выбранной организации
  const [isRepresented, setIsRepresented] = useState(false);

  const handleSelectChange = (value: number) => {
    setSelectedOrg(value);
  };

  const handleCloseByKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      setIsRepresented((prev) => !prev);
    }
  };

  const handleDelete = () => {
    setSelectedOrg(null);
  };

  const menu = (
    <Menu>
      <Menu.Item key="delete" icon={<BasketIcon />} onClick={handleDelete}>
        Удалить
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="table-container" id="journals-construction-table">
      <Typography.Title level={4}>
        Лицо, осуществляющее строительство
      </Typography.Title>
      {!selectedOrg && (
        <div style={{ marginTop: "14px" }}>
          <div>
            <div style={{ marginBottom: "8px", fontSize: "14px" }}>
              Выберите организацию
            </div>
            <Select
              style={{ width: "50%" }}
              placeholder="Выберите организацию"
              onChange={handleSelectChange}
            >
              {organizations.map((org) => (
                <Select.Option key={org.id} value={org.id}>
                  {org.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      )}

      {selectedOrg && (
        <div
          className={`${isRepresented ? "table-container table-border-line" : ""}`}
          onKeyDown={handleCloseByKey}
          role="button"
          tabIndex={0}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "24px",
              alignItems: "center",
            }}
          >
            <Typography.Title level={5} style={{ fontWeight: "400" }}>
              {organizations.find((org) => org.id === selectedOrg)?.name}
            </Typography.Title>
            <Dropdown overlay={menu} trigger={["click"]}>
              <MoreOneIcon
                style={{ cursor: "pointer" }}
                width={24}
                height={24}
              />
            </Dropdown>
          </div>

          <Row
            gutter={[16, 16]}
            style={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {tableMock.map(({ id, title, info }) => (
              <Fragment key={id}>
                <Col span={8} style={{ color: "#888888" }}>
                  {title}
                </Col>
                <Col span={16} className="table-info-content">
                  {info ? <div>{info}</div> : null}
                </Col>
              </Fragment>
            ))}
          </Row>

          {isRepresented && (
            <div className="represented-container">
              <div
                className="flex-between"
                style={{
                  fontSize: 16,
                  marginBottom: "16px",
                }}
              >
                <div className="flex-default">
                  <div className="represented-icon-container">
                    <SuccessIcon />
                  </div>
                  Подписано
                </div>
                <div>11.07.2024 13:10</div>
              </div>
              <div style={{ fontSize: 12, marginBottom: 8 }}>
                Доверенность № 123-32b-1w1e-77er
              </div>
              <div style={{ fontSize: 12 }}>с 11.07.2024 по 11.07.2025</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
