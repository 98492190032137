import { Checkbox, Dropdown, Space, Table } from "antd";
import "./journals-settings-title.scss";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";

interface DataType {
  key: string;
  dateTime: string;
  event: string;
  employee: string;
  position: string;
  comment: string;
}

const mockOptions = [
  "Создание ОЖР",
  "Отправка на проверку",
  'Статус изменен с "Новый" на "На согласовании"',
  "Сохранение внесенных изменений в титульный лист",
  "Отзыв с согласования титульного листа",
  'Статус изменен с "На согласовании" на "Отменен"',
  'Статус изменен с "Отменен" на "Новый"',
  'Статус изменен с "Новый" на "На согласовании"',
  "Титульный лист успешно проверен",
  'Статус изменен с "На согласовании" на "Подготовка XML"',
  "XML успешно подготовлен",
  'Статус изменен с "Подготовка XML" на "На подписании"',
  'Статус изменен с "Подготовка XML" на "Ошибка документа"',
  'Статус изменен с "Ошибка документа" на "Подготовка XML"',
  'Статус изменен с "На согласовании" на "Отклонен"',
  'Статус изменен с "Отклонен" на "Новый"',
  'Статус изменен с "На подписании" на "Отклонен"',
  "Пользователь %ФИО сотрудника% оставил замечание",
  'Статус изменен с "Подписан" на "Отправлен в ГСН"',
  'Статус изменен с "Отправлен в ГСН" на "Отклонен"',
  'Статус изменен с "Отправлен в ГСН" на "Ошибка взаимодействия"',
  'Статус изменен с "Отправлен в ГСН" на "Замечания от ГСН"',
  'Статус изменен с "Отправлен в ГСН" на "Зарегистрирован в ГСН"',
];

const data: DataType[] = [
  {
    key: "1",
    dateTime: "16.08.2024 16:13 МСК",
    event: "Отправлено на согласование",
    employee: "Бузлаев Евгений",
    position: "Администратор",
    comment: "Наконец",
  },
  {
    key: "2",
    dateTime: "15.08.2024 15:13 МСК",
    event: "Подписан блок “Уполномоченный представитель авторского надзора”",
    employee: "Уполномоченный Иван",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "3",
    dateTime: "14.08.2024 13:13 МСК",
    event: "Статус изменен на “На подписании”",
    employee: "Бузлаев Евгений",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "4",
    dateTime: "14.08.2024 12:13 МСК",
    event: "Статус изменен на “Подготовка документа в ГСН”",
    employee: "Бузлаев Евгений",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "5",
    dateTime: "13.08.2024 17:29 МСК",
    event: "Статус изменен на “На проверке”",
    employee: "Аббасова Юлия",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "6",
    dateTime: "13.08.2024 16:27 МСК",
    event: "Статус изменен на “На доработку”",
    employee: "Бузлаев Евгений",
    position: "Администратор",
    comment: "Неправильный уполномоченный представитель заказчика",
  },
  {
    key: "7",
    dateTime: "12.08.2024 23:13 МСК",
    event: "Статус изменен на “На проверке”",
    employee: "Аббасова Юлия",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "8",
    dateTime: "12.08.2024 16:54 МСК",
    event: "...",
    employee: "Аббасова Юлия",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "9",
    dateTime: "07.08.2024 16:23 МСК",
    event:
      "%UserName добавил Уполномоченный представитель Технический заказчик",
    employee: "Аббасова Юлия",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "10",
    dateTime: "06.08.2024 16:34 МСК",
    event:
      "%UserName добавил организацию ООО “Организация” в поле Авторский надзор",
    employee: "Аббасова Юлия",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "11",
    dateTime: "05.08.2024 14:25 МСК",
    event: "Назначен отправляющий в ГСН титульный лист %UserName",
    employee: "Харин Дмитрий",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "12",
    dateTime: "05.08.2024 13:04 МСК",
    event: "Назначен проверяющий титульного листа %UserName",
    employee: "Харин Дмитрий",
    position: "Администратор",
    comment: "-",
  },
  {
    key: "13",
    dateTime: "03.08.2024 12:08 МСК",
    event: "Назначен заполняющий титульного листа %UserName",
    employee: "Харин Дмитрий",
    position: "Администратор",
    comment: "-",
  },
];

export const JournalsSettingsTitle: React.FC = () => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const handleCheckboxChange = (option: string) => {
    setCheckedItems((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option],
    );
  };
  const columns = [
    {
      title: "Дата, Время",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (text: string) => {
        const [date, time] = text.split(" ");
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{ fontWeight: 500, color: "#333333", marginRight: "4px" }}
            >
              {date}
            </span>
            <span style={{ color: "#888888" }}>{time}</span>
          </div>
        );
      },
    },
    {
      title: "Событие",
      dataIndex: "event",
      key: "event",
      render: (text: string) => (
        <span style={{ color: "#333333" }}>{text}</span>
      ),
    },
    {
      title: "Сотрудник",
      dataIndex: "employee",
      key: "employee",
      render: (text: string) => (
        <span style={{ color: "#1976D2", cursor: "pointer" }}>{text}</span>
      ),
    },
    {
      title: "Должность",
      dataIndex: "position",
      key: "position",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
      render: (text: string) => <span>{text}</span>,
    },
  ];

  const menuContent = (
    <div className="dropdown-menu">
      <Space direction="vertical" size="middle">
        {mockOptions.map((option) => (
          <Checkbox
            key={option}
            onChange={() => handleCheckboxChange(option)}
            checked={checkedItems.includes(option)}
          >
            {option}
          </Checkbox>
        ))}
      </Space>
    </div>
  );

  return (
    <div className="table-container" style={{ marginTop: 24 }}>
      <div className="add-buttons-container">
        <Dropdown
          overlay={menuContent}
          trigger={["click"]}
          overlayStyle={{
            width: 400,
            maxHeight: 400,
            overflowY: "scroll",
            backgroundColor: "white",
          }}
        >
          <button className="add-button">
            Сотрудник <PlusCircleOutlined className="plus-icon" />
          </button>
        </Dropdown>

        <button className="add-button">
          Событие <PlusCircleOutlined className="plus-icon" />
        </button>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        bordered={false}
        rowKey="key"
      />
    </div>
  );
};
