import { useState } from "react";
import { Modal, Radio, RadioChangeEvent } from "antd";
import "./object-modal-add-address.scss";
import { ObjectFormBuilder, ObjectFormMail } from "./ui";

interface IObjectModalAddress {
  isModalAddress: boolean;
  setIsModalAddres: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ObjectModalAddress = ({
  isModalAddress,
  setIsModalAddres,
}: IObjectModalAddress) => {
  const [selectedType, setSelectedType] = useState(1);

  const handleModalClose = () => {
    setIsModalAddres(false);
  };

  const handleSelectType = (item: RadioChangeEvent) => {
    setSelectedType(item.target.value);
  };

  return (
    <Modal
      title={"Добавление адреса"}
      open={isModalAddress}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      footer={null}
      centered
      width={400}
    >
      <div>
        <div>
          <div
            className="form-label-input primary"
            style={{ marginBottom: "8px" }}
          >
            Тип адреса
          </div>
          <Radio.Group value={selectedType} onChange={handleSelectType}>
            <Radio value={1}>Почтовый</Radio>
            <Radio value={2}>Строительный</Radio>
          </Radio.Group>
        </div>
        {selectedType === 1 ? <ObjectFormBuilder /> : <ObjectFormMail />}
      </div>
    </Modal>
  );
};
