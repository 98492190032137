import { EditOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";
import { ProjectDetails } from "@features/project-details";
import { useRouter } from "@shared/lib/hooks";
import { routes } from "@shared/lib/react-router";
import "./object-card.scss";
import { IObject } from "@/shared/api/object";

const { Title, Text } = Typography;

interface IObjectCard {
  handleIsForm: (event: any, status: boolean) => void;
  projectId: string;
  object: IObject;
}
export const ObjectCard = ({
  handleIsForm,
  projectId,
  object,
}: IObjectCard) => {
  const { navigate } = useRouter();
  const goToObjectPage = () => {
    navigate(routes.objects.object(projectId, object.buildingObjectId));
  };
  return (
    <Card className="custom-card" onClick={goToObjectPage} bordered={false}>
      <div className="card-content">
        <div className="card-info">
          <Title level={5} className="card-title">
            {object.shortName || ""}
          </Title>
          <Text className="card-address">
            {object.address.addressString || ""}
          </Text>
          <ProjectDetails tasks={99} files={99} folders={99} />
        </div>
        <div className="card-actions">
          <div className="icon-container">
            <EditOutlined
              className="icon-custom edit-icon"
              onClick={(event) => handleIsForm(event, true)}
            />
            {/* <DeleteOutlined className="icon-custom delete-icon" onClick={showModal} /> */}
          </div>

          <Text className="card-tag">{object.address.addressType || ""}</Text>
        </div>
      </div>
    </Card>
  );
};
