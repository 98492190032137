import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { ProjectJournalsView } from "./ui/project-journals";

export const createProjectJournalsView = (
  ...rest: RouteObject[]
): RouteObject => {
  return {
    path: `${routes.journals.root()}`,
    element: createElement(ProjectJournalsView),
    children: [...rest],
  };
};
