import { ProjectCardSkeleton } from "./project-card-skeleton";
import "./project-page-skeleton.scss";

export function ProjectPageSkeleton() {
  return (
    <div className="project-page-skeleton-grid">
      {Array.from({ length: 3 }, (_, index) => (
        <ProjectCardSkeleton key={index} />
      ))}
    </div>
  );
}
