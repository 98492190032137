import { useEffect } from "react";
import { Card, Button, Row, Col, Avatar, Table, Space, Typography } from "antd";
import { useParams } from "react-router-dom";
import example_image from "@shared/assets/png/exemple-project-1.png";
import "./project-detail.scss";

const data = [
  {
    key: "1",
    avatar: "https://xsgames.co/randomusers/avatar.php?g=male", // URL аватара
    fullName: "Харин Дмитрий",
    organization: "ООО “Интэнт”",
    position: "Дизайнер",
    email: "dima@builddocs.ru",
    phone: "+7 (921) 334-4000",
  },
];

const columns = [
  {
    title: "ФИО",
    dataIndex: "fullName",
    key: "fullName",
    render: (text: string, record: any) => (
      <Space>
        <Avatar src={record.avatar} style={{ width: "30px", height: "30px" }} />
        <Typography.Text>{text}</Typography.Text>
      </Space>
    ),
  },
  {
    title: "Организация",
    dataIndex: "organization",
    key: "organization",
  },
  {
    title: "Должность",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
  },
];

export const ProjectDetail = () => {
  // TODO Вынести в отдельный хук useParams
  const { projectId } = useParams();
  const projectData = " ";

  useEffect(() => {
    // TODO Здесь логика получения данных проекта с сервера
    // setProjectData(123);
  }, [projectId]);

  if (!projectData) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <Card style={{ marginTop: 16 }}>
        <Row
          gutter={[16, 16]}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Col
            xs={24}
            lg={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {" "}
            <img
              src={example_image as string}
              alt="Загородный дом"
              style={{ borderRadius: "8px" }}
            />
          </Col>
          <Col xs={24} lg={18} style={{ paddingLeft: "16px" }}>
            <div className="project-info-container">
              <div className="project-title-container">
                <div className="project-title">Загородный дом</div>
                {/* <Button type="link">Редактировать</Button> */}
              </div>
              <div className="project-description">
                Загородный дом Дмитрия в Горной Шальдихе
              </div>
              <div className="description">
                <div className="description-item">
                  <span className="label">Адрес</span>
                  <span className="value">
                    Ленинградская область, Кировский район, дер. Горная
                    Шальдиха, ул. Хвойная уч. 40
                  </span>
                </div>
                <div className="description-item">
                  <span className="label">ID проекта</span>
                  <span className="value">
                    <div>d6a5bae4-4b08-11ec-b6c8-d00d126464f0</div>

                    <Button type="link">Скопировать</Button>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="table-container" style={{ marginTop: "16px" }}>
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 24,
          }}
        >
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Администратор проекта
          </Typography.Title>
          <Button type="link">Добавить</Button>
        </Space>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
        />
      </div>
    </>
  );
};
