import { Card, Flex, Skeleton } from "antd";
import "./project-card-skeleton.scss";

export function ProjectCardSkeleton() {
  return (
    <Card className="project-card-skeleton">
      <Flex gap={20} vertical>
        <Skeleton.Image className="project-card-skeleton-image" active={true} />
        <Skeleton.Input
          size="small"
          block={true}
          className="project-card-skeleton-details"
          active={true}
        />
        <Skeleton active={true} />
      </Flex>
    </Card>
  );
}
