import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { JournalsRegistrationDetails } from "./ui";

export const JournalsRegistrationDetailsRoute = (
  ...rest: RouteObject[]
): RouteObject => {
  return {
    path: `${routes.journals.details()}`,
    element: createElement(JournalsRegistrationDetails),
    children: [...rest],
  };
};
