import type { Project } from "@entities/project";
import type { LegacyApi, ModernApi } from "@shared/api";

type ProjectTypes = LegacyApi.ProjectCardDto | ModernApi.ProjectCardDto;

/* eslint-disable no-param-reassign */
// Если оставим - добавить тестирование, тк. опасная функция.
// Тип будем определять уже в возвращаемом значении вызывающей функции через assertion.
function renameProperty(
  object: any,
  from: [keyof LegacyApi.ProjectCardDto, keyof ModernApi.ProjectCardDto],
  to: keyof Project,
) {
  if (from[0] in object && from[0] !== to) {
    object[to] = object[from[0]];
    delete object[from[0]];
  } else if (from[1] in object && from[1] !== to) {
    object[to] = object[from[1]];
    delete object[from[1]];
  }
  return object;
}
/* eslint-enable no-param-reassign */

function coerceToGenericProject(project: ProjectTypes) {
  renameProperty(project, ["projectId", "projectId"], "id");
  renameProperty(project, ["avatar", "projectAvatarId"], "avatar");
  renameProperty(project, ["projectName", "shortName"], "shortName");
  renameProperty(project, ["description", "fullName"], "fullName");
  renameProperty(
    project,
    ["constructionAddress", "addressUnstructured"],
    "address",
  );
  return project as unknown as Project;
}

export function mergeProjectArraysWithDedupe(
  legacyProjects: Array<LegacyApi.ProjectCardDto> = [],
  modernProjects: Array<ModernApi.ProjectCardDto> = [],
): Array<Project> {
  const projectsMap = new Map<string, Project>();

  legacyProjects.forEach((project) => {
    const newInstance = { ...project, isModern: false };
    const modified = coerceToGenericProject(newInstance);
    projectsMap.set(project.projectId, modified);
  });

  modernProjects.forEach((project) => {
    const newInstance = { ...project, isModern: true };
    const modified = coerceToGenericProject(newInstance);
    projectsMap.set(project.externalId ?? project.projectId, modified);
  });

  return [...projectsMap.values()];
}
