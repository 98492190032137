import { Button } from "antd";
import "./journals-side-table.scss";
import {
  BackCheckIcon,
  CloseIcon,
  DoneIcon,
  GreenDotIcon,
  RedDotIcon,
  SaveIcon,
  SendCheckIcon,
} from "@/shared/assets/svg/icons";
import { useState } from "react";
import { JournalsType } from "../journals-title/model/title-info-mock";
import { SideModalReject } from "./ui";

const isShowDot = false;

interface IJournalsSideTable {
  journalsList: Record<string, JournalsType>;
}

export const JournalsSideTable = ({ journalsList }: IJournalsSideTable) => {
  const [modalConfig, setModalConfig] = useState({
    isVisible: false,
    type: "",
    title: "",
  });

  const openModal = (type: string, title: string) => {
    setModalConfig({
      isVisible: true,
      type,
      title,
    });
  };

  const closeModal = () => {
    setModalConfig({
      isVisible: false,
      type: "",
      title: "",
    });
  };

  const handleHashNavigate = (item: string) => {
    window.location.hash = item;
  };

  return (
    <div className="table-container-side" id="journals-side-table">
      <ul className="info-section-list">
        {Object.keys(journalsList).map((item) => (
          <li
            key={journalsList[item].isName.en}
            className="info-section-list-item"
            onClick={() => handleHashNavigate(journalsList[item].isName.en)}
          >
            {isShowDot ? (
              <>
                {journalsList[item].info ? (
                  <GreenDotIcon
                    width={6}
                    height={6}
                    style={{ marginRight: "10px" }}
                  />
                ) : (
                  <RedDotIcon
                    width={6}
                    height={6}
                    style={{ marginRight: "10px" }}
                  />
                )}
              </>
            ) : null}

            <div>{journalsList[item].isName.ru}</div>
          </li>
        ))}
      </ul>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="separator" />
        <div>
          <Button
            type="primary"
            style={{ marginTop: "16px" }}
            icon={<SaveIcon />}
            onClick={() => openModal("save", "Сохранить изменения")}
          >
            Сохранить
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            style={{ marginTop: "16px" }}
            icon={<SendCheckIcon />}
            onClick={() => openModal("reasonForSubmission", "Отправить на проверку")}
          >
            Отправить на проверку
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            style={{ marginTop: "16px" }}
            icon={<BackCheckIcon />}
            onClick={() => openModal("withdraw", "Отозвать")}
          >
            Отозвать
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            style={{ marginTop: "16px" }}
            icon={<DoneIcon />}
            onClick={() => openModal("return", "Вернуть в работу")}
          >
            Вернуть в работу
          </Button>
        </div>
        <div className="separator" style={{ marginTop: 16 }} />
        <div>
          <Button
            type="primary"
            style={{ marginTop: "16px" }}
            icon={<DoneIcon />}
            onClick={() => openModal("verified", "Проверено")}
          >
            Проверено
          </Button>
        </div>
        <div>
          <Button
            type="default"
            style={{ marginTop: "16px" }}
            icon={<CloseIcon />}
            onClick={() => openModal("reject", "Отклонить")}
          >
            Отклонить
          </Button>
        </div>
      </div>

      <SideModalReject
        isVisible={modalConfig.isVisible}
        onClose={closeModal}
        title={modalConfig.title}
        type={modalConfig.type}
      />
    </div>
  );
};
