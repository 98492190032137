import { useState } from "react";
import { Avatar, Button, Divider, Modal, Space, Table, Typography } from "antd";
import "./representation-modal.scss";
import { RepresentationFormAdd } from "../representation-form-add";
import { RepresentationFormEmail } from "../representation-form-email";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { BasketIcon } from "@/shared/assets/svg/icons";
import { RepresentationFormAuthor } from "../representation-form-author";
const { Text } = Typography;

interface IRepresentationMoodal {
  isRepresentation: boolean;
  handleClose: () => void;
  handleAdd: () => void;
  title?: string;
  type?: "settings" | "author";
}

const data = [
  {
    key: "1",
    name: "Застройщиков Иван",
    organization: "ООО Построй-ка",
    position: "Директор",
    initials: "ЗИ",
  },
];

// Колонки таблицы

export const RepresentationMoodal = ({
  isRepresentation,
  handleClose,
  handleAdd,
  title = "Поиск сотрудника по адресу электронной почты",
  type,
}: IRepresentationMoodal) => {
  const [isShowFormAdd, setIsShowFormAdd] = useState(false);
  const handleIsShowFalse = () => {
    setIsShowFormAdd(false);
  };
  const handleOnCancel = () => {
    handleClose();
    handleIsShowFalse();
  };
  const handleLocalAdd = () => {
    handleAdd();
    setIsShowFormAdd(false);
  };
  const columns = [
    {
      title: "ФИО",
      dataIndex: "name",
      key: "name",
      render: (name: string, record: any) => (
        <Space size="middle">
          <Avatar>{record.initials}</Avatar>
          <Text>{name}</Text>
        </Space>
      ),
    },
    {
      title: "Организация",
      dataIndex: "organization",
      key: "organization",
      render: (organization: string) => <Text>{organization}</Text>,
    },
    {
      title: "Должность",
      dataIndex: "position",
      key: "position",
      render: (position: string) => <Text>{position}</Text>,
    },
    {
      key: "action",
      render: () => (
        <Button
          type="text"
          icon={
            type === "settings" ? (
              <CloseCircleOutlined style={{ color: "red", fontSize: "16px" }} />
            ) : (
              <BasketIcon />
            )
          }
          danger
          style={{ padding: 0 }}
          onClick={handleIsShowFalse}
        />
      ),
    },
  ];
  return (
    <>
      <Modal
        open={isRepresentation}
        onCancel={handleOnCancel}
        footer={null}
        centered
        width={700}
        title="Добавление Уполномоченного"
      >
        <div style={{ marginBottom: "24px", color: "#333333" }}>{title}</div>

        <RepresentationFormEmail setIsShowFormAdd={setIsShowFormAdd} />

        {isShowFormAdd ? (
          <div className="rep-author-modal">
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              showHeader
              bordered={false}
              style={{ width: "100%" }}
            />
          </div>
        ) : null}

        {isShowFormAdd && type !== "settings" ? (
          <RepresentationFormAdd />
        ) : null}

        {type === "author" ? <RepresentationFormAuthor /> : null}

        <Divider />
        <Button
          type="primary"
          disabled={!isShowFormAdd}
          onClick={handleLocalAdd}
        >
          Добавить
        </Button>
      </Modal>
    </>
  );
};
