import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";

export const useCoordinator = () => {
  const { search } = useLocation();

  const { projectId, objectId } = useParams<{
    projectId: string;
    objectId: string;
  }>();

  return {
    isProjects: Boolean(projectId),
    projectId,
    objectId,
    search: {
      ...queryString.parse(search),
    },
  };
};
