import { useQuery, UseQueryResult } from "@tanstack/react-query";
import type { LegacyApi, ModernApi } from "@shared/api";
import { ProjectQueriesTags, ProjectService } from "@shared/api/project";

interface IUseGetProjects {
  page: number;
  limit: number;
}

export function useGetProjects(
  { page, limit }: IUseGetProjects = { page: 1, limit: 1000 },
) {
  const legacyQuery = useQuery({
    queryFn: () =>
      ProjectService.getAllProjectLegacy({
        pageNumber: page - 1,
        pageSize: limit,
      }),
    queryKey: ProjectQueriesTags.getAllProjectsLegacy({
      pageNumber: page - 1,
      pageSize: limit,
    }),
  });

  const query = useQuery({
    queryFn: () => ProjectService.getAllProjects({ page, limit }),
    queryKey: ProjectQueriesTags.getAllProjects({ page, limit }),
  });

  return [legacyQuery, query] as [
    UseQueryResult<LegacyApi.GetProjectsResponse, Error>,
    UseQueryResult<ModernApi.GetProjectsResponse, Error>,
  ];
}
