import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ProjectService, ProjectQueriesTags } from "@shared/api/project";

export function useGetProjectAdmins(projectId: string) {
  return useQuery({
    queryKey: ProjectQueriesTags.getProjectAdmins({ projectId }),
    queryFn: () => ProjectService.getProjectAdmin(projectId),
  });
}

export function useAddProjectAdmin(projectId: string) {
  return useMutation({
    mutationFn: (userId: string) => ProjectService.addProjectAdmin(projectId, userId),
  });
}

export function useRemoveProjectAdmin(projectId: string) {
  return useMutation({
    mutationFn: (userId: string) => ProjectService.removeProjectAdmin(projectId, userId),
  });
}
