import { Card, Button, Row, Col, Avatar, Table, Space, Typography } from "antd";
import { useCoordinator, useDisclosure } from "@/shared/lib/hooks";
import {
  useAddProjectAdmin,
  useGetProjectAdmins,
} from "@/entities/project/queries/use-project-admins";
import example_image from "@shared/assets/png/exemple-project-1.png";
import "./project-detail.scss";
import { RepresentationMoodal } from "@/features/representation-modal";
import { Can } from "@/shared/lib/casl";

const columns = [
  {
    title: "ФИО",
    dataIndex: "fullName",
    key: "fullName",
    render: (text: string, record: any) => (
      <Space>
        <Avatar src={record.avatar} style={{ width: "30px", height: "30px" }} />
        <Typography.Text>{text}</Typography.Text>
      </Space>
    ),
  },
  {
    title: "Организация",
    dataIndex: "organization",
    key: "organization",
  },
  {
    title: "Должность",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
  },
];

export const ProjectDetail = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { projectId } = useCoordinator();
  const { data: admins, isLoading } = useGetProjectAdmins(
    projectId ? projectId : "",
  );
  const { mutateAsync, isPending, error } = useAddProjectAdmin(
    projectId ? projectId : "",
  );

  const handleAddAdmin = (id?: string) => {
    id && mutateAsync(id);
  };

  const transformedData =
    admins?.userInfo?.map((admin) => ({
      key: admin.userId,
      // avatar: admin.userAvatarId
      //   ? `https://your-image-service/${admin.userAvatarId}`
      //   : `https://xsgames.co/randomusers/avatar.php?g=male`,
      avatar: "https://xsgames.co/randomusers/avatar.php?g=male",
      fullName: `${admin.userSurname} ${admin.userFirstName} ${admin.userMiddleName ?? ""}`,
      organization: admin.userCompanies?.[0]?.shortName || "Не указано",
      position: admin.userCompanies?.[0]?.position || "Не указано",
      email: admin.userEmail,
      phone: admin.userMobilePhone,
    })) || [];

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <Card style={{ marginTop: 16 }}>
        <Row
          gutter={[16, 16]}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Col
            xs={24}
            lg={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={example_image as string}
              alt="Загородный дом"
              style={{ borderRadius: "8px" }}
            />
          </Col>
          <Col xs={24} lg={18} style={{ paddingLeft: "16px" }}>
            <div className="project-info-container">
              <div className="project-title-container">
                <div className="project-title">Загородный дом</div>
              </div>
              <div className="project-description">
                Загородный дом Дмитрия в Горной Шальдихе
              </div>
              <div className="description">
                <div className="description-item">
                  <span className="label">Адрес</span>
                  <span className="value">
                    Ленинградская область, Кировский район, дер. Горная
                    Шальдиха, ул. Хвойная уч. 40
                  </span>
                </div>
                <div className="description-item">
                  <span className="label">ID проекта</span>
                  <span className="value">
                    <div>d6a5bae4-4b08-11ec-b6c8-d00d126464f0</div>
                    <Button type="link">Скопировать</Button>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="table-container" style={{ marginTop: "16px" }}>
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 24,
          }}
        >
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Администраторы проекта
          </Typography.Title>
          <Can I="access" a="project">
            <Button type="link" onClick={onOpen}>
              Добавить
            </Button>
          </Can>
        </Space>
        <Table
          columns={columns}
          dataSource={transformedData}
          pagination={false}
          bordered={false}
        />
      </div>
      <RepresentationMoodal
        isRepresentation={isOpen}
        handleClose={onClose}
        handleAdd={handleAddAdmin}
        title="Добавить администратора"
        type="settings"
        isLoading={isPending}
        //@ts-ignore
        error={error?.response?.data?.errorDescription}
      />
    </>
  );
};
