import { FC, useState } from "react";
import { Dropdown, Menu, Table, Tooltip } from "antd";
import {
  VectorIcon,
  StatusSignedIcon,
  StatusPendingIcon,
  StatusDeclinedIcon,
  CommentIcon,
} from "@/shared/assets/svg/icons";

import "./journals-subscribers-table.scss";

type StatusKey = "signed" | "pending" | "declined";

type StatusIcon = {
  title: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
};

type StatusIcons = Record<StatusKey, StatusIcon>;

const statusIcons: StatusIcons = {
  signed: {
    title: "Подписано",
    Icon: StatusSignedIcon,
  },
  pending: {
    title: "Ожидает подписания",
    Icon: StatusPendingIcon,
  },
  declined: {
    title: "Отклонил подписание",
    Icon: StatusDeclinedIcon,
  },
};

interface TableDataType {
  key: string;
  name: string;
  role: string;
  status: keyof typeof statusIcons;
  dateTime: { date: string; time: string };
  message: string | null;
}

const dataSource: Readonly<TableDataType[]> = [
  {
    key: "1",
    name: "Казикова Елена",
    role: "Уполномоченный представитель застройщика",
    status: "signed",
    message: null,
    dateTime: { date: "11.07.2024", time: "13:10" },
  },
  {
    key: "2",
    name: "Застройщиков Иван",
    role: "Уполномоченный представитель застройщика",
    status: "declined",
    message: null,
    dateTime: { date: "11.07.2024", time: "13:10" },
  },
  {
    key: "3",
    name: "Харин Дмитрий",
    role: "Уполномоченный представитель технического заказчика",
    status: "pending",
    message: null,
    dateTime: { date: "11.07.2024", time: "13:10" },
  },
  {
    key: "4",
    name: "Проверяев Андрей",
    role: "Уполномоченный представитель лица, осуществляющего строительство",
    status: "pending",
    message: "Сообщение от подписанта",
    dateTime: { date: "11.07.2024", time: "13:10" },
  },
  {
    key: "5",
    name: "Стройконтролев Сергей",
    role: "Уполномоченный застройщика или тех заказчика по вопросам стройконтроля",
    status: "pending",
    message: null,
    dateTime: { date: "11.07.2024", time: "13:10" },
  },
  {
    key: "6",
    name: "Васильев Михаил",
    role: "Уполномоченный представитель лица, ответственного за эксплуатацию здания",
    status: "pending",
    message: null,
    dateTime: { date: "11.07.2024", time: "13:10" },
  },
];

export const JournalsSubscribersTable: FC = () => {
  const [expanded, setExpanded] = useState(false);

  const columns = [
    {
      title: "ФИО",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: TableDataType) => (
        <Dropdown
          overlay={
            <div
              style={{
                display: "flex",
                padding: "24px",
                background: "#fff",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "16px",
                alignItems: "center",
              }}
            >
              <img
                src="https://via.placeholder.com/50"
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  marginRight: "12px",
                }}
              />
              <div>
                <div style={{ marginBottom: "8px", fontWeight: "bold" }}>
                  {record.name}
                </div>
                <div style={{ marginBottom: "8px" }}>
                  Наименование организации
                </div>
                <div style={{ marginBottom: "8px", color: "#888" }}>
                  {record.role}
                </div>
                <div style={{ marginBottom: "8px" }}>
                  <a
                    href={`mailto:${record.message || "example@example.com"}`}
                    style={{ color: "#1976d2" }}
                  >
                    {record.message || "example@example.com"}
                  </a>
                </div>
                <div>+7 (921) 334-4321</div>
              </div>
            </div>
          }
          trigger={["click"]}
        >
          <Tooltip title="Краткое наименование организации">
            <a style={{ color: "#1976d2", cursor: "pointer" }}>{text}</a>
          </Tooltip>
        </Dropdown>
      ),
    },

    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Статус подписания",
      dataIndex: "status",
      key: "status",
      render: (status: keyof typeof statusIcons, message: string | null) => {
        const { title, Icon } = statusIcons[status];
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ marginRight: "8px" }} />
            {title}
            {message ? (
              <Tooltip title="Какое-то сообщение от отвественного лица">
                <CommentIcon
                  style={{ marginLeft: "16px", cursor: "pointer" }}
                />
              </Tooltip>
            ) : null}
          </span>
        );
      },
    },
    {
      title: "Дата время",
      dataIndex: "dateTime",
      key: "dateTime",
      render: ({ date, time }: { date: string; time: string }) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ color: "#333" }}>{date}</div>
            <div style={{ marginLeft: "4px", color: "#888" }}>{time}</div>
          </div>
        );
      },
    },
  ];

  return (
    <div
      className="table-container table-container-scroll-tab"
      id="journals-subscribers-table"
    >
      <div onClick={() => setExpanded(!expanded)} className="table-header">
        <span
          style={expanded ? { marginBottom: 12 } : {}}
          className="expandable-icon-container"
        >
          <div className={`expandable-icon ${expanded ? "open" : ""}`}>
            <VectorIcon />
          </div>
          <div>Список подписантов</div>
        </span>
      </div>
      <div
        className={`table-body ${expanded ? "open" : "closed"} table-journal`}
      >
        <Table
          dataSource={dataSource}
          //@ts-ignore
          columns={columns}
          pagination={false}
          bordered={false}
          rowClassName={(record: { key: string }) =>
            record?.key === "999" ? "table-row-grey" : ""
          }
        />
      </div>
    </div>
  );
};
