import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import { Link } from "react-router-dom";
import "./representation-form-add.scss";

type LoadDocumentsMockType = {
  [key: string]: { title: string; status: boolean; id: number };
};

export const RepresentationFormAdd = () => {
  const [loadDocuments, setLoadDocuments] = useState<LoadDocumentsMockType>({
    firstDocument: {
      title: "Прикрепить документ",
      status: false,
      id: 1,
    },
    secondElement: {
      title: "Прикрепить подпись",
      status: false,
      id: 2,
    },
  });

  const toggleDocumentStatus = (key: string) => {
    setLoadDocuments((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        status: !prev[key].status,
      },
    }));
  };

  return (
    <>
      <div className="confirmation-credentials">
        <div className="confirmation-credentials-inputs-container">
          <div className="confirmation-credentials-label">
            <div className="confirmation-credentials-title">
              Наименование документа
            </div>
            <Input placeholder="Приказ" />
          </div>
          <div className="confirmation-credentials-label">
            <div className="confirmation-credentials-title">
              Номер документа
            </div>
            <Input placeholder="№ГГ-12" />
          </div>
          <div className="confirmation-credentials-label">
            <div className="confirmation-credentials-title">Дата выдачи </div>
            <Input placeholder="12.12.2012" type="date" />
          </div>
        </div>
      </div>
      <div className="document-selector-container">
        {Object.keys(loadDocuments).map((item, index) => {
          return loadDocuments[item].status ? (
            <div
              className="document-selector-button"
              key={loadDocuments[item].id}
              style={index === 0 ? { marginBottom: "16px" } : {}}
            >
              <Link to="/" className="document-selector-button-title link">
                Название документа.pdf (4kB)
              </Link>
              <CloseOutlined
                onClick={() => toggleDocumentStatus(item)}
                className="close-icon"
              />
            </div>
          ) : (
            <div
              className="document-selector-button"
              style={
                index === 0
                  ? { marginBottom: "16px", justifyContent: "space-between" }
                  : { justifyContent: "space-between" }
              }
              key={loadDocuments[item].id}
            >
              <div className="document-selector-button-title">
                {loadDocuments[item].title}
              </div>
              <Button onClick={() => toggleDocumentStatus(item)}>
                Загрузить
              </Button>
            </div>
          );
        })}
      </div>
      <div className="organization-credentials">
        <div className="organization-credentials-label">
          <div className="organization-credentials-title">
            Организация, выдавшая документ (необ.)
          </div>
          <Input placeholder="" />
        </div>
        <div className="organization-credentials-label">
          <div className="organization-credentials-title">Описание (необ.)</div>
          <Input placeholder="" />
        </div>
      </div>
    </>
  );
};
