import { buildsServiceApi } from "@shared/api";
import {
  GetObjectRequest,
  GetObjectResponse,
  GetObjectsRequest,
  GetObjectsResponse,
} from "./types";

export class ObjectService {
  static getAllObjects(data: GetObjectsRequest): Promise<GetObjectsResponse> {
    return buildsServiceApi
      .get(`projects/${data.projectId}/building-objects`, {
        params: {
          limit: data.limit || 1000,
          page: data.page || 1,
        },
      })
      .then((r) => r.data);
  }

  static getObject(data: GetObjectRequest): Promise<GetObjectResponse> {
    return buildsServiceApi
      .get(`building-objects/${data.objectId}`)
      .then((r) => r.data);
  }
}
