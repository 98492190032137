import { createContext, useEffect, useMemo } from "react";
import {
  PureAbility,
  AbilityBuilder,
  SubjectType,
  ExtractSubjectType,
  SubjectRawRule,
} from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { ModernApi } from "@shared/api";

type Actions = "access" | "read" | "edit" | "delete";
export type Subjects =
  | "company"
  | "project"
  | "object"
  | "acts_service"
  | "contract"
  | "journals_service"
  | "general_work_journal"
  | "act"
  | "title"
  | SubjectType;

export type AppAbility = PureAbility<[Actions, Subjects]>;

// @ts-expect-error TODO: я умею только так
export const AbilityContext = createContext<AppAbility>();
export const Can = createContextualCan(AbilityContext.Consumer);

export const PermissionsMap = {
  ADMIN: ["access", "read", "edit", "delete"],
  ALL_USER: ["access", "read", "edit"],
  ACCESS: ["access"],
  READ: ["read"],
  EDIT: ["edit"],
  DELETE: ["delete"],
};

type SubjectRule = SubjectRawRule<
  string,
  ExtractSubjectType<Subjects>,
  unknown
>;

export function ProjectAbilityBuilder(
  project: ModernApi.ProjectResponse,
): SubjectRule[] {
  const subject: Subjects = "project";
  const actions = [] as Actions[];

  project?.permissions.forEach((permission) => {
    if (permission.action === "ADMIN" || permission.action === "ALL_USER") {
      //@ts-ignore
      actions.push(...PermissionsMap[permission.action]);
    } else {
      //@ts-ignore
      actions.push(...PermissionsMap[permission.action]);
    }
  });

  const { can, rules } = new AbilityBuilder<PureAbility<[Actions, Subjects]>>(
    PureAbility,
  );

  actions?.forEach((action) => {
    can(action, subject);
  });

  return rules;
}

type Entity = ModernApi.ProjectResponse;

export function useUpdateAbility({
  entity,
  currentAbility,
  abilityBuilder,
}: {
  entity: Entity;
  currentAbility: AppAbility;
  abilityBuilder: (arg0: Entity) => SubjectRule[];
}) {
  const newAbility = useMemo(
    () => abilityBuilder(entity),
    [abilityBuilder, entity],
  );

  useEffect(() => {
    //@ts-ignore
    currentAbility.update(newAbility);
  }, [currentAbility, newAbility]);
}
