import { useState } from "react";
import "./project-related.scss";
import { ProjectRelatedContracts } from "../project-related-contracts/project-related-contracts";
import { ProjectRelatedJournals } from "../project-related-journals/project-related-journals";
import { ProjectRelatedTabs } from "@/features/project-related-tabs";

export const ProjectRelated = () => {
  const [activeTab, setActiveTab] = useState("Договоры");
  const tabs = ["Договоры", "Журналы"];
  return (
    <>
      <ProjectRelatedTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        journalInfo={{
          message: null,
          status: {
            prevStatusText: "",
            prevStatusColor: "",
            prevStatusBackground: "",
            statusText: "",
            statusColor: "",
            statusBackground: "",
          },
          isSignedTables: false,
          isHideButtons: false,
          isHideTableInfo: false,
          currentStatus: {
            label: "Новый",
            textColor: "#000000",
            backgroundColor: "#FFFFFF",
          },
        }}
      />
      {activeTab === "Договоры" ? (
        <ProjectRelatedContracts />
      ) : (
        <ProjectRelatedJournals />
      )}
    </>
  );
};
