import { useState } from "react";
import { Typography, Modal, Button } from "antd";
import "./project-objects.scss";
import { ObjectFormCreate } from "@/features/oject-form";
import { useObjects } from "@entities/object";
import { useCoordinator } from "@shared/lib/hooks";
import { ObjectCard } from "../object-card";
import { ObjectCardSkeleton } from "../object-card-skeleton";

const { Title, Text } = Typography;
export const ProjectObjects = () => {
  const [isForm, setIsForm] = useState(false);
  const { projectId } = useCoordinator() as {
    projectId: string;
  };

  const { data, isLoading } = useObjects({
    projectId,
  });

  const handleIsForm = (event: any, status: boolean) => {
    event.stopPropagation();
    setIsForm(status);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isForm ? <ObjectFormCreate handleIsForm={handleIsForm} /> : null}
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Title level={3} className="project-members-title">
            Список обьектов строительства
          </Title>
          {/* <Button>Добавить обьект</Button> */}
        </div>
        <div className="project-object-cards-container">
          {isLoading && !data && <ObjectCardSkeleton />}
          {!isLoading &&
            data &&
            data?.buildingObjects?.length &&
            data.buildingObjects.map((object) => (
              <ObjectCard
                key={object.buildingObjectId}
                handleIsForm={handleIsForm}
                projectId={projectId}
                object={object}
              />
            ))}
          {!isLoading && !data && (
            <p className="project-objects-empty">Объекты не созданы</p>
          )}
        </div>
      </>
      <Modal
        title="Удалить?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={400}
      >
        <div>
          <Text>Вы действительно хотите удалить?</Text>
          <div
            style={{
              height: "1px",
              backgroundColor: "#e0e0e0",
              margin: "24px 0",
            }}
          />
          <div
            style={{
              marginTop: "24px",
              display: "flex",
              gap: "8px",
            }}
          >
            <Button type="primary" onClick={handleOk}>
              Да
            </Button>
            <Button onClick={handleCancel}>Нет</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
