import { Tabs } from "antd";
import Tab from "antd/es/tabs/TabPane";
import clsx from "clsx";
import { ManageIcon, ProjectIcon } from "@shared/assets/svg/icons";
import "./tabs-menu.scss";
import { ExtendableComponent } from "@shared/types/components.ts";

interface TabsMenuProps extends ExtendableComponent {}

export const TabsMenu = ({ className }: TabsMenuProps) => {
  return (
    <Tabs
      className={clsx("tabs-menu", className)}
      defaultActiveKey="1"
      tabPosition="top"
    >
      <Tab
        style={{ marginRight: "24px" }}
        tab={
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ProjectIcon
              width={24}
              height={24}
              style={{ marginRight: "8px" }}
            />
            <div style={{ fontSize: "16px", fontWeight: "300" }}>Проекты</div>
          </span>
        }
        key="1"
      />
      <Tab
        tab={
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ManageIcon width={24} height={24} style={{ marginRight: "8px" }} />
            <div style={{ fontSize: "16px", fontWeight: "300" }}>
              Управление
            </div>
          </span>
        }
        key="2"
      />
    </Tabs>
  );
};
