import { CrossStorageClient } from "cross-storage";

import "./journals-title.scss";

import { useState } from "react";
import { JournalsCommissionerTable } from "../../../journals-commissioner-table";
import { JournalsConstructionDetailsTable } from "../../../journals-construction-details-table";
import { JournalsConstructionTable } from "../../../journals-construction-table";
import { JournalsCustomerTable } from "../../../journals-customer-table";
import { JournalsDocumentationTable } from "../../../journals-documentation-table";
import { JournalsExpandableTable } from "../../../journals-expandable-table";
import { JournalsExpertiseTable } from "../../../journals-expertise-table";
import { JournalsInfoTable } from "../../../journals-info-table";
import { JournalsIntelligenceTable } from "../../../journals-intelligence-table";
import { JournalsOperationTable } from "../../../journals-operation-table";
import { JournalsOtherTable } from "../../../journals-other-table";
import { JournalsPermissionTable } from "../../../journals-permission-table";
import { JournalsRepresentationTable } from "../../../journals-representation-table/ui";
import { JournalsRepresentativeTable } from "../../../journals-representative-table";
import { JournalsSideTable } from "../../../journals-side-table";
import { JournalsSubscribersTable } from "../../../journals-subscribers-table";
import { JournalsSupervisionTable } from "../../../journals-supervision-table";
import { JournalsTecCustomerTable } from "../../../journals-tec-customer-table";
import { initialState } from "../../model/title-info-mock";
import { useDisclosure } from "@/shared/lib/hooks";
import { SettingModal } from "../setting-modal";
import { Button } from "antd";
import { useOutletContext } from "react-router-dom";
import {
  journalInfoType,
  signStatusEnum,
} from "@/app/composition/views/project-journals/ui/project-journals";
import { JournalsEpresentativeTable } from "../../../journals-epresentative-table/journals-epresentative-table";
import { JournalsConstructionRepresentativeTable } from "../../../journals-construction-representative-table/journals-construction-representative-table";

// import { env } from "@/shared/config";

// const storage = new CrossStorageClient(env.OLD_FRONTEND_URL, {});

// storage
//   .onConnect()
//   .then(() => {
//     return storage.get("token");
//   })
//   .then((tokens) => {
//     console.log("Полученные токены:", tokens);
//   })
//   .catch((err) => {
//     console.error("Ошибка доступа к токенам:", err);
//   });

export const JournalsTitle = () => {
  const [journalsList, setJournalsList] = useState(initialState);
  const { journalInfo, setJournalInfo, signStatus, setSignStatus } =
    useOutletContext<{
      journalInfo: journalInfoType;
      setJournalInfo: React.Dispatch<React.SetStateAction<journalInfoType>>;
      signStatus: signStatusEnum;
      setSignStatus: React.Dispatch<React.SetStateAction<signStatusEnum>>;
    }>();

  return (
    <>
      <JournalsExpandableTable />
      <JournalsSubscribersTable />
      <div className="journals-columns-wrapper">
        <div className="journals-main-column-wrapper">
          {journalsList.InfoTable.isShow ? <JournalsInfoTable /> : null}
          {journalsList.IntelligenceTable.isShow ? (
            <JournalsIntelligenceTable journalInfo={journalInfo} />
          ) : null}
          {journalsList.RepresentationTable.isShow ? (
            <JournalsRepresentationTable isSignedTables={true} />
          ) : null}
          {journalsList.TecCustomerTable.isShow ? (
            <JournalsTecCustomerTable />
          ) : null}
          {journalsList.CommissionerTable.isShow ? (
            <JournalsCommissionerTable
              isRepresented={true}
              signStatus={signStatus}
              setSignStatus={setSignStatus}
            />
          ) : null}
          {journalsList.PermissionTable.isShow ? (
            <JournalsPermissionTable />
          ) : null}
          {journalsList.DocumentationTable.isShow ? (
            <JournalsDocumentationTable />
          ) : null}
          {journalsList.SupervisionTable.isShow ? (
            <JournalsSupervisionTable />
          ) : null}
          {journalsList.ExpertiseTable.isShow ? (
            <JournalsExpertiseTable />
          ) : null}
          {journalsList.ConstructionTable.isShow ? (
            <JournalsConstructionTable />
          ) : null}
          {journalsList.RepresentativeTable.isShow ? (
            <JournalsRepresentativeTable />
          ) : null}
          {journalsList.CustomerTable.isShow ? <JournalsCustomerTable /> : null}
          {journalsList.ExpertiseTable ? <JournalsEpresentativeTable /> : null}
          {journalsList.ConstructionRepresentativeTable ? (
            <JournalsConstructionRepresentativeTable />
          ) : null}
          {journalsList.OperationTable.isShow ? (
            <JournalsOperationTable />
          ) : null}
          {journalsList.OtherTable.isShow ? <JournalsOtherTable /> : null}
          {journalsList.ConstructionDetailsTable.isShow ? (
            <JournalsConstructionDetailsTable />
          ) : null}
        </div>
        <div className="journals-side-column-wrapper">
          <JournalsSideTable journalsList={journalsList} />
        </div>
        <SettingModal
          journalsList={journalsList}
          setJournalsList={setJournalsList}
          journalInfo={journalInfo}
          setJournalInfo={setJournalInfo}
          signStatus={signStatus}
          setSignStatus={setSignStatus}
        />
      </div>
    </>
  );
};

