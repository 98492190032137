import { Card, Switch } from "antd";
import "./settings-card-item.scss";

interface ISettingsCardItem {
  id: number;
  title: string;
  description: string;
  author: string;
  date: string;
  isActive?: boolean;
}

export const SettingsCardItem = ({
  title,
  description,
  author,
  date,
  isActive,
}: ISettingsCardItem) => {
  return (
    <Card className="project-setting-card" bordered={false}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <h3 className="project-setting-card-title">{title}</h3>
        <Switch
          checked={isActive}
          defaultChecked={true}
          className="project-setting-card-switch"
          style={{ marginTop: "0px" }}
        />
      </div>

      {description && (
        <p className="project-setting-card-description">{description}</p>
      )}
      <div className="project-setting-card-footer">
        <span className="project-setting-card-author">{author}</span>
        <span className="project-setting-card-date">{date}</span>
      </div>
    </Card>
  );
};
