import { Radio, Button } from "antd";
import { useState } from "react";
import "./reason-submission-modal-content.scss";

export const ReasonForSubmissionModalContent = () => {
  const [value, setValue] = useState<string | null>(null);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <>
      <div style={{ marginTop: "24px", marginBottom: "16px" }}>
        Выберите причину
      </div>
      <Radio.Group
        onChange={onChange}
        value={value}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Radio value="newDocument">Новый подготовленный документ</Radio>
        <Radio value="changes">Изменения по замечаниям</Radio>
        <Radio value="changesGsn">Изменения по замечаниям ГСН</Radio>
      </Radio.Group>
      <div style={{ display: "flex", gap: "8px", marginTop: 16 }}>
        <Button type="primary">Отправить на проверку</Button>
        <Button>Отменить</Button>
      </div>
    </>
  );
};

