import { ReactNode, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  notification,
  Typography,
} from "antd";
import { formatISO } from "date-fns";
import { useForm } from "react-hook-form";
import { FormItem } from "react-hook-form-antd";
import { useCreateProjectMutation } from "@entities/project";
import { useGetProjects } from "@entities/project/queries/use-get-projects.ts";
import { User } from "@entities/user";
import { SelectAuthorizedRepresentationWrapper } from "@features/representation-modal/";
import CustomerBlock from "@pages/projects/ui/create-project-wrapper/ui/customer-block.tsx";
import { projectTypesDto, projectContractsDto } from "@shared/api/project";
import { useDisclosure } from "@shared/lib/hooks";
// TODO: создать апи для организаций и фичу где тип инициализируем
import "./create-project-wrapper.scss";
// eslint-disable-next-line import/order
import { UserService } from "@shared/api/user";

export function CreateProjectWrapper({
  children,
}: {
  children: ({ onOpen }: { onOpen: () => void }) => ReactNode;
}) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { data: oldUser } = useQuery({
    queryFn: UserService.oldService_UserCurrentQuery,
    queryKey: ["user", "old", "current"],
  });
  const [legacyQuery] = useGetProjects();
  const methods = useForm<projectTypesDto.CreateProjectDto>({
    resolver: zodResolver(projectContractsDto.CreateProjectDtoSchema),
  });
  const customerAssigneeWatcher = methods.watch("customerAssigneeEmploymentId");
  const developerAssigneeWatcher = methods.watch(
    "developerAssigneeEmploymentId",
  );
  useEffect(() => {
    if (oldUser) {
      // @ts-ignore
      methods.reset({ inn: oldUser.data?.employment?.position.inn as string });
    }
  }, [oldUser]);
  const { mutateAsync } = useCreateProjectMutation();

  const prepareDate = (date: any) =>
    date
      ? formatISO(new Date(date), {
          representation: "date",
        })
      : "";
  const getInnKpp: (inn?: string, kpp?: string) => string = (inn, kpp) =>
    inn ? (kpp ? `${inn}-${kpp}` : inn) : "";
  const [api, contextHolder] = notification.useNotification();
  const handleSubmit = (data: any) => {
    const prepareData = {
      ...data,
      customer: {
        address: data.customer.address,
        inn: getInnKpp(data.customer?.inn, data.customer?.kpp),
        issueDate: prepareDate(data.customer.issueDate),
        kpp: data.customer.kpp,
        ogrn: data.customer.ogrn,
        phone: data.customer.phone,
        sro: data.customer.sro,
        sroDate: prepareDate(data.customer?.sroDate),
        title: data.customer.title,
      },
      developer: {
        address: data.developer.address,
        inn: getInnKpp(data.developer?.inn, data.developer?.kpp),
        issueDate: prepareDate(data.developer.issueDate),
        kpp: data.developer.kpp,
        ogrn: data.developer.ogrn,
        phone: data.developer.phone,
        sro: data.developer.sro,
        sroDate: prepareDate(data.developer?.sroDate),
        title: data.developer.title,
      },
    };

    mutateAsync(prepareData)
      .then(() => {
        api.success({
          message: "Успешно",
          description: "Создали новый проект",
          placement: "topRight",
          showProgress: false,
        });
        legacyQuery.refetch();
        onClose();
      })
      .catch((e) => {
        api.warning({
          message: "Не удалось создать проект",
          description: `${e.message}`,
          placement: "topRight",
          showProgress: false,
        });
      });
  };
  const addCustomerAssignee = (assignee: User) => {
    return methods.setValue(
      "customerAssigneeEmploymentId",
      assignee?.employment?.employmentId || assignee.userId,
    );
  };

  const addDeveloperAssignee = (assignee: any) => {
    return methods.setValue(
      "developerAssigneeEmploymentId",
      assignee?.employment?.employmentId || assignee.userId,
    );
  };
  useEffect(() => {
    methods.reset();
  }, [isOpen]);
  return (
    <>
      {children({ onOpen })}
      {contextHolder}
      <Modal
        title="Создание проекта"
        open={isOpen}
        onOk={methods.handleSubmit(handleSubmit, (errors) => {
          console.error("Validation errors:", errors);
        })}
        onCancel={onClose}
        destroyOnClose
        okText="Сохранить"
        cancelText="Отмена"
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      >
        <Form>
          <Flex vertical gap={16}>
            <FormItem
              style={{ marginBottom: 0 }}
              control={methods.control}
              name="description"
            >
              <Input placeholder="Описание" />
            </FormItem>
            <FormItem
              style={{ marginBottom: 0 }}
              control={methods.control}
              name="constructionAddress"
            >
              <Input placeholder="Адрес" />
            </FormItem>
          </Flex>
          <Divider />

          <CustomerBlock methods={methods} representative="customer" />
          <Typography.Title level={5}>
            Администратор проекта от ген.подрядчика
          </Typography.Title>
          {customerAssigneeWatcher ? (
            <p
              style={{
                color: "#333333",
                display: "flex",
                alignItems: "center",
                gap: 16,
              }}
            >
              {customerAssigneeWatcher}{" "}
              <CloseOutlined
                onClick={() =>
                  methods.setValue("customerAssigneeEmploymentId", undefined)
                }
                style={{ color: "red", cursor: "pointer" }}
              />
            </p>
          ) : (
            <SelectAuthorizedRepresentationWrapper
              setRepresentative={addCustomerAssignee}
            >
              {({ onOpen }) => (
                <Button onClick={onOpen}>Добавить представителя</Button>
              )}
            </SelectAuthorizedRepresentationWrapper>
          )}
          <Divider />
          <CustomerBlock methods={methods} representative="developer" />
          <Typography.Title level={5}>
            Администратор проекта от заказчика
          </Typography.Title>
          {developerAssigneeWatcher ? (
            <p
              style={{
                color: "#333333",
                display: "flex",
                alignItems: "center",
                gap: 16,
                marginBottom: 24,
              }}
            >
              {developerAssigneeWatcher}{" "}
              <CloseOutlined
                onClick={() =>
                  methods.setValue("customerAssigneeEmploymentId", undefined)
                }
                style={{ color: "red", cursor: "pointer" }}
              />
            </p>
          ) : (
            <Flex style={{ marginBottom: 24 }}>
              <SelectAuthorizedRepresentationWrapper
                setRepresentative={addDeveloperAssignee}
              >
                {({ onOpen }) => (
                  <Button onClick={onOpen}>Добавить представителя</Button>
                )}
              </SelectAuthorizedRepresentationWrapper>
            </Flex>
          )}
        </Form>
      </Modal>
    </>
  );
}
