import { Typography } from "antd";
import { Outlet } from "react-router-dom";
import "./project-general-view.scss";
import { ProjectTabs } from "@/features/project-tabs";
import { useProject } from "@entities/project";
import { useCoordinator } from "@shared/lib/hooks";

const { Title } = Typography;

export const ProjectGeneralView = () => {
  const { projectId } = useCoordinator() as {
    projectId: string;
  };
  const { data } = useProject({ projectId });
  return (
    <div className="general-view-container">
      <Title level={3} style={{ marginBottom: "32px" }}>
        {data.shortName}
      </Title>
      <ProjectTabs />
      <Outlet />
    </div>
  );
};
