import { TableInfoType } from "@/features/table-info";

export type tableIntelligenceMockType = {
  name: string;
  table: TableInfoType[];
  key: number;
};

export const tableIntelligenceMock: tableIntelligenceMockType[] = [
  {
    name: "Застройщик",
    key: 1,
    table: [
      { id: 1, title: "Тип", info: "Застройщик" },
      { id: 2, title: "ИНН", info: "7839136848" },
      { id: 3, title: "КПП", info: "783901001" },
      { id: 4, title: "ОГРН", info: "1217800024265" },
      { id: 5, title: "Контакты", info: "8 (800) 123-7777" },
      {
        id: 6,
        title: "Адрес",
        info: "190020, г. Санкт-Петербург, вн.тер.г. Муниципальный Округ Екатерингофский, наб Обводного Канала, д. 138, к. 1, литера А, офис 432, пом. 7Н-10",
      },
    ],
  },
  {
    name: "Эксплуатирующая организация",
    key: 2,
    table: [
      { id: 1, title: "Тип", info: "Эксплуатирующая организация" },
      { id: 2, title: "ИНН", info: "6549136842" },
      { id: 3, title: "КПП", info: "654901003" },
      { id: 4, title: "ОГРН", info: "1127600024265" },
      { id: 5, title: "Контакты", info: "8 (800) 555-1212" },
      {
        id: 6,
        title: "Адрес",
        info: "190005, г. Москва, ул. Тверская, д. 7, офис 101",
      },
    ],
  },
  {
    name: "Региональный оператор",
    key: 3,
    table: [
      { id: 1, title: "Тип", info: "Региональный оператор" },
      { id: 2, title: "ИНН", info: "1123456789" },
      { id: 3, title: "КПП", info: "112301004" },
      { id: 4, title: "ОГРН", info: "1134600056789" },
      { id: 5, title: "Контакты", info: "8 (499) 123-4567" },
      {
        id: 6,
        title: "Адрес",
        info: "123456, г. Екатеринбург, ул. Мира, д. 15, офис 4",
      },
    ],
  },
  {
    name: "Подрядчик",
    key: 4,
    table: [
      { id: 1, title: "Тип", info: "Подрядчик" },
      { id: 2, title: "ИНН", info: "9876543210" },
      { id: 3, title: "КПП", info: "987601002" },
      { id: 4, title: "ОГРН", info: "1245600028765" },
      { id: 5, title: "Контакты", info: "8 (812) 345-6789" },
      {
        id: 6,
        title: "Адрес",
        info: "654321, г. Казань, ул. Баумана, д. 9, офис 202",
      },
    ],
  },
  {
    name: "Заказчик",
    key: 5,
    table: [
      { id: 1, title: "Тип", info: "Заказчик" },
      { id: 2, title: "ИНН", info: "1239874561" },
      { id: 3, title: "КПП", info: "123401008" },
      { id: 4, title: "ОГРН", info: "1278900012345" },
      { id: 5, title: "Контакты", info: "8 (905) 789-4561" },
      {
        id: 6,
        title: "Адрес",
        info: "123654, г. Новосибирск, просп. Ленина, д. 2, офис 405",
      },
    ],
  },
  {
    name: "Инвестор",
    key: 6,
    table: [
      { id: 1, title: "Тип", info: "Инвестор" },
      { id: 2, title: "ИНН", info: "1928374655" },
      { id: 3, title: "КПП", info: "192801009" },
      { id: 4, title: "ОГРН", info: "1294500098765" },
      { id: 5, title: "Контакты", info: "8 (963) 123-9876" },
      {
        id: 6,
        title: "Адрес",
        info: "432198, г. Владивосток, ул. Пушкина, д. 17, офис 10",
      },
    ],
  },
  {
    name: "Девелопер",
    key: 7,
    table: [
      { id: 1, title: "Тип", info: "Девелопер" },
      { id: 2, title: "ИНН", info: "7654321987" },
      { id: 3, title: "КПП", info: "765401001" },
      { id: 4, title: "ОГРН", info: "1326540090123" },
      { id: 5, title: "Контакты", info: "8 (777) 555-3333" },
      {
        id: 6,
        title: "Адрес",
        info: "987654, г. Самара, ул. Молодёжная, д. 25, офис 18",
      },
    ],
  },
  {
    name: "Проектировщик",
    key: 8,
    table: [
      { id: 1, title: "Тип", info: "Проектировщик" },
      { id: 2, title: "ИНН", info: "2345678912" },
      { id: 3, title: "КПП", info: "234501005" },
      { id: 4, title: "ОГРН", info: "1234567890123" },
      { id: 5, title: "Контакты", info: "8 (999) 666-4444" },
      {
        id: 6,
        title: "Адрес",
        info: "456123, г. Уфа, ул. Центральная, д. 1, офис 105",
      },
    ],
  },
  {
    name: "Архитектор",
    key: 9,
    table: [
      { id: 1, title: "Тип", info: "Архитектор" },
      { id: 2, title: "ИНН", info: "3456789123" },
      { id: 3, title: "КПП", info: "345601007" },
      { id: 4, title: "ОГРН", info: "3456789012345" },
      { id: 5, title: "Контакты", info: "8 (888) 111-2222" },
      {
        id: 6,
        title: "Адрес",
        info: "123789, г. Красноярск, ул. Лесная, д. 8, офис 302",
      },
    ],
  },
  {
    name: "Генеральный подрядчик",
    key: 10,
    table: [
      { id: 1, title: "Тип", info: "Генеральный подрядчик" },
      { id: 2, title: "ИНН", info: "9876541234" },
      { id: 3, title: "КПП", info: "987601008" },
      { id: 4, title: "ОГРН", info: "9876543210123" },
      { id: 5, title: "Контакты", info: "8 (111) 222-3333" },
      {
        id: 6,
        title: "Адрес",
        info: "654987, г. Ростов-на-Дону, ул. Советская, д. 11, офис 208",
      },
    ],
  },
];
