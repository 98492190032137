import React, { useState } from "react";
import { DatePicker, Typography, ConfigProvider } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import "./custom-date-picker.scss";
import CalendarIcon from "@/shared/assets/svg/icons/calendar-icon.png";

dayjs.locale("ru");

export const CustomDatePicker: React.FC = () => {
  const [startConstruction, setStartConstruction] =
    useState<dayjs.Dayjs | null>(null);
  const [endConstruction, setEndConstruction] = useState<dayjs.Dayjs | null>(
    null,
  );
  const [startJournal, setStartJournal] = useState<dayjs.Dayjs | null>(null);
  const [endJournal, setEndJournal] = useState<dayjs.Dayjs | null>(null);

  const handleConstructionStartChange = (date: dayjs.Dayjs | null) => {
    setStartConstruction(date);
  };

  const handleConstructionEndChange = (date: dayjs.Dayjs | null) => {
    setEndConstruction(date);
  };

  const handleJournalStartChange = (date: dayjs.Dayjs | null) => {
    setStartJournal(date);
  };

  const handleJournalEndChange = (date: dayjs.Dayjs | null) => {
    setEndJournal(date);
  };

  return (
    <div className="custom-date-picker">
      <div className="picker-group">
        <div>
          <Typography.Text>Начало строительства</Typography.Text>
          <DatePicker
            value={startConstruction}
            onChange={handleConstructionStartChange}
            disabledDate={(date) => date && date.isAfter(dayjs())}
            suffixIcon={<img src={CalendarIcon} width={24} height={24} />}
            placeholder=""
            className="custom-picker"
          />
        </div>
        <div>
          <Typography.Text>Окончание строительства</Typography.Text>
          <DatePicker
            value={endConstruction}
            onChange={handleConstructionEndChange}
            disabledDate={(date) =>
              date &&
              (startConstruction ? date.isBefore(startConstruction) : false)
            }
            suffixIcon={<img src={CalendarIcon} width={24} height={24} />}
            placeholder=""
            className="custom-picker"
          />
        </div>
      </div>
      <div style={{ marginTop: "24px", marginBottom: "24px" }}>
        В журнале содержится учет выполнения работ в период
      </div>
      <div className="picker-group">
        <div>
          <Typography.Text>Начало ведения журнала</Typography.Text>
          <DatePicker
            value={startJournal}
            onChange={handleJournalStartChange}
            suffixIcon={<img src={CalendarIcon} width={24} height={24} />}
            placeholder=""
            className="custom-picker"
          />
        </div>
        <div>
          <Typography.Text>Окончание ведения журнала</Typography.Text>
          <DatePicker
            value={endJournal}
            onChange={handleJournalEndChange}
            disabledDate={(date) =>
              date && (startJournal ? date.isBefore(startJournal) : false)
            }
            suffixIcon={<img src={CalendarIcon} width={24} height={24} />}
            placeholder=""
            className="custom-picker"
          />
        </div>
      </div>
    </div>
  );
};
