import { TableInfoType } from "@/features/table-info";

export type tableMockType = {
  name: string;
  table: TableInfoType[];
  key: number;
};

export const tableMock: tableMockType[] = [
  {
    name: "ТехЗаказчик",
    key: 1,
    table: [
      { id: 2, title: "ИНН", info: "7839136848" },
      { id: 3, title: "КПП", info: "783901001" },
      { id: 4, title: "ОГРН", info: "1217800024265" },
      { id: 5, title: "Контакты", info: "8 (800) 123-7777" },
      {
        id: 6,
        title: "Адрес",
        info: "190020, г. Санкт-Петербург, вн.тер.г. Муниципальный Округ Екатерингофский, наб Обводного Канала, д. 138, к. 1, литера А, офис 432, пом. 7Н-10",
      },
    ],
  },
];
