import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { ProjectSettings } from "@/pages/general/project-settings/ui";
import { routes } from "@shared/lib/react-router";

export const ProjectSettingsRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.general.settings()}`,
    element: createElement(ProjectSettings),
    children: [...rest],
  };
};
