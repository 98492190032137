import { Col, Dropdown, Menu, Row, Select, Typography } from "antd";
import { tableMock, tableMockType } from "./tableMock";
import { Fragment, useState } from "react";
import { BasketIcon, CopyIcon, MoreOneIcon } from "@/shared/assets/svg/icons";

export const JournalsTecCustomerTable = () => {
  const [selectedOrg, setSelectedOrg] = useState<tableMockType | null>(null);

  const handleChangeOrg = (key: number) => {
    const foundOrg = tableMock.find((item) => item.key === key);
    setSelectedOrg(foundOrg || null);
  };

  const handleDelete = () => {
    setSelectedOrg(null);
  };

  const menu = (
    <Menu>
      <Menu.Item key="delete" icon={<BasketIcon />} onClick={handleDelete}>
        Удалить
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="table-container" id="journals-tec-customer-table">
      <Typography.Title level={4}>Технический заказчик</Typography.Title>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "14px" }}
      >
        {selectedOrg ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginBottom: "16px", fontSize: "16px" }}>
                ООО "{selectedOrg.name}"
              </div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <MoreOneIcon
                  style={{ cursor: "pointer" }}
                  width={24}
                  height={24}
                />
              </Dropdown>
            </div>
            <Row gutter={[16, 16]}>
              {selectedOrg.table.map(({ id, title, info }) => (
                <Fragment key={id}>
                  <Col span={8} style={{ color: "#888888" }}>
                    {title}
                  </Col>
                  <Col span={16} className="table-info-content">
                    {info}
                  </Col>
                </Fragment>
              ))}
            </Row>
          </>
        ) : (
          <>
            <div>
              <div style={{ marginBottom: "4px" }}>Организация</div>
              <Select
                placeholder="Введите название или ИНН"
                onChange={handleChangeOrg}
              >
                {tableMock.map((item) => {
                  return (
                    <Select.Option key={item.key} value={item.key}>
                      <div>OOO "{item.name}"</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          color: "#888888",
                        }}
                      >
                        <div>ИНН "{item.table[1].info}"</div>
                        <div>КПП "{item.table[2].info}"</div>
                        <div>ОГРН "{item.table[3].info}"</div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
