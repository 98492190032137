import { CopyIcon, BasketIcon, MoreOneIcon } from "@/shared/assets/svg/icons";
import { Typography, Select, Button, Menu, Col, Dropdown, Row } from "antd";
import { Fragment, useState } from "react";
import { tableMockType } from "../journals-tec-customer-table/tableMock";

const tableMock: tableMockType[] = [
  {
    name: "Проектное бюро",
    key: 1,
    table: [
      { id: 2, title: "ИНН", info: "7839136848" },
      { id: 3, title: "КПП", info: "783901001" },
      { id: 4, title: "ОГРН", info: "1217800024265" },
      { id: 5, title: "Контакты", info: "8 (800) 123-7777" },
      {
        id: 6,
        title: "Адрес",
        info: "190020, г. Санкт-Петербург, пр. Невский, д. 111, к. 1",
      },
    ],
  },
  {
    name: "СРО Проектирование",
    key: 2,
    table: [
      { id: 7, title: "Номер", info: "302" },
      { id: 8, title: "Дата", info: "12.12.2017" },
      { id: 9, title: "Выдано", info: "ГАП СРО" },
      { id: 10, title: "ИНН", info: "7734268438" },
      { id: 11, title: "ОГРН", info: "1097799009428" },
    ],
  },
];

export const JournalsDocumentationTable = () => {
  const [selectedOrg, setSelectedOrg] = useState<tableMockType | null>(null);

  const handleChangeOrg = (key: number) => {
    const foundOrg = tableMock.find((item) => item.key === key);
    setSelectedOrg(foundOrg || null);
  };

  const handleDelete = () => {
    setSelectedOrg(null);
  };

  const menu = (
    <Menu>
      <Menu.Item key="delete" icon={<BasketIcon />} onClick={handleDelete}>
        Удалить
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="table-container" id="journals-documentation-table">
      <Typography.Title level={4}>
        Лицо, осуществляющее подготовку проектной документации
      </Typography.Title>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "14px" }}
      >
        {selectedOrg ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "14px",
              }}
            >
              <div
                style={{
                  marginBottom: "16px",
                  fontSize: "16px",
                }}
              >
                ООО "{selectedOrg.name}"
              </div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <MoreOneIcon
                  style={{ cursor: "pointer" }}
                  width={24}
                  height={24}
                />
              </Dropdown>
            </div>
            <Row gutter={[16, 16]}>
              {selectedOrg.table.map(({ id, title, info }) => (
                <Fragment key={id}>
                  <Col span={8} style={{ color: "#888888" }}>
                    {title}
                  </Col>
                  <Col span={16} className="table-info-content">
                    {info}
                  </Col>
                </Fragment>
              ))}
            </Row>
          </>
        ) : (
          <>
            <div>
              <div style={{ marginBottom: "4px" }}>Организация</div>
              <Select
                placeholder="Введите название или ИНН"
                onChange={handleChangeOrg}
              >
                {tableMock.map((item) => {
                  return (
                    <Select.Option key={item.key} value={item.key}>
                      <div>OOO "{item.name}"</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          color: "#888888",
                        }}
                      >
                        <div>ИНН "{item.table[1].info}"</div>
                        <div>КПП "{item.table[2].info}"</div>
                        <div>ОГРН "{item.table[3].info}"</div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
