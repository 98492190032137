import { ObjectMembersList } from "../object-members-list";

export const ObjectMembers = () => {
  return (
    <>
      {/* <ObjectMembersEmpty /> */}
      <ObjectMembersList />
    </>
  );
};
