import axios from "axios";
import { env } from "@shared/config.ts";
import { checkToken } from "@shared/lib/session-service";

const command = import.meta.env.VITE_COMMAND;

const createApi = (baseURL: string, settings = { useOldToken: false }) => {
  const api = axios.create({
    baseURL,
  });

  api.interceptors.request.use((config) => {
    return checkToken(config, settings);
  });

  return api;
};

export const userServiceApi = createApi("/user-service");
export const buildsServiceApi = createApi("/builds-service/api/v1");
export const s3AdapterApi = createApi("/s3-adapter/api/v1");
export const oldBaseApi =
  command === "serve"
    ? createApi("/old-builddocs-api", { useOldToken: true })
    : createApi(`${env.OLD_FRONTEND_URL}`, { useOldToken: true });

export type { ModernApi, LegacyApi } from "./project";
