import { z } from "zod";

export const OrganizationBaseSchema = z.object({
  inn: z.string(),
  kpp: z.string().optional(),
  phone: z.string().optional(),
  sro: z.string().nullable().optional(),
  sroDate: z.string().nullable().optional(),
  sroEngineering: z.string().nullable().optional(),
  sroEngineeringDate: z.string().nullable().optional(),
});

const PhysicalAddressSchema = z.object({
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  postalCode: z.string().optional(),
  country: z.string().optional(),
});

export const OrganizationSchema = OrganizationBaseSchema.extend({
  name: z.string(),
  ogrn: z.string(),
  ogrnDate: z.string(),
  sroIssuerInn: z.string().nullable().optional(),
  sroIssuerName: z.string().nullable().optional(),
  sroIssuerOgrn: z.string().nullable().optional(),
  sroEngineeringName: z.string().nullable().optional(),
  sroEngineeringOgrn: z.string().nullable().optional(),
  sroEngineeringInn: z.string().nullable().optional(),
  legalAddress: z.any().optional(),
  physicalAddress: PhysicalAddressSchema.optional(),
  createDateTime: z.string().optional(),
  apiToken: z.string().optional(),
});

export const CreateProjectDtoSchema = z.object({
  projectId: z.string().optional(),
  inn: z.string(),
  description: z.string({
    message: "должно быть значение. Пример локализации формы",
  }),
  constructionAddress: z
    .string({
      message: "Должно быть значение",
    })
    .min(6, "Минимальная длина 6")
    .max(12, "Максимальная длина 12"),
  developer: OrganizationSchema.optional(),
  customer: OrganizationSchema.optional(),
  customerAssigneeEmploymentId: z.string().optional(),
  developerAssigneeEmploymentId: z.string().optional(),
  createDateTime: z.string().optional(),
});

export const CreateProjectResponseSchema = z.object({
  projectId: z.string().optional(),
  inn: z.string(),
  description: z.string(),
  constructionAddress: z.string(),
  developer: OrganizationSchema.optional(),
  customer: OrganizationSchema.optional(),
  developerAssigneeEmploymentId: z.string(),
  customerAssigneeEmploymentId: z.string(),
  createDateTime: z.string().optional(),
});
