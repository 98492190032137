import { Fragment, useState } from "react";
import { Button, Col, Dropdown, Menu, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { RepresentationMoodal } from "@/features/representation-modal";
import {
  EditIcon,
  SuccessIcon,
  BasketIcon,
  MoreOneIcon,
} from "@/shared/assets/svg/icons";
import PdfIcon from "@/shared/assets/svg/icons/pdf-icon.png";
import SigIcon from "@/shared/assets/svg/icons/sig-icon.png";
import { useDisclosure } from "@/shared/lib/hooks";

const tableMock = [
  {
    id: 1,
    title: "Организация",
    info: "ООО “Застройщик”",
  },
  {
    id: 2,
    title: "Должность",
    info: "Заместитель директора",
  },
  {
    id: 3,
    title: "Документ, №, дата",
    info: "Приказ КК-ТЗ-77, 12.10.2021, подпись.sig",
    isCopy: true,
  },
  {
    id: 4,
    title: "Почта",
    info: "izastroyschikov@zastroyschik.ru",
    isEmail: true,
  },
  {
    id: 5,
    title: "Номер в НРС",
    info: "С-47-000001",
  },
  {
    id: 6,
    title: "Телефон",
    info: "8 (921) 123-3210",
  },
];

interface IJournalsRepresentationTable {
  isSignedTables?: boolean;
  isHideTableInfo?: boolean;
  isHideButtons?: boolean;
}

export const JournalsRepresentationTable: React.FC<
  IJournalsRepresentationTable
> = ({
  isSignedTables = false,
  isHideTableInfo = false,
  isHideButtons = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [employees, setEmployees] = useState<
    { id: number; isRepresented: boolean }[]
  >([]);

  const handleAdd = () => {
    setEmployees((prev) => [
      ...prev,
      { id: prev.length + 1, isRepresented: isSignedTables },
    ]);
    onClose();
  };

  const handleDelete = (id: number) => {
    setEmployees((prev) => prev.filter((employee) => employee.id !== id));
  };

  const handleToggleRepresented = (id: number) => {
    setEmployees((prev) =>
      prev.map((employee) =>
        employee.id === id
          ? { ...employee, isRepresented: !employee.isRepresented }
          : employee,
      ),
    );
  };

  const menu = (id: number) => (
    <Menu>
      <Menu.Item key="edit" icon={<EditIcon />} onClick={onOpen}>
        Редактировать
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<BasketIcon />}
        onClick={() => handleDelete(id)}
      >
        Удалить
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="table-container table-representation"
      id="journals-representation-table"
    >
      <Typography.Title
        level={4}
        style={isHideTableInfo && isHideButtons ? { marginBottom: 24 } : {}}
      >
        Уполномоченный представитель застройщика
      </Typography.Title>
      {employees.map((employee) => (
        <div
          key={employee.id}
          style={{ marginBottom: "24px" }}
          className={` ${employee.isRepresented ? "table-border-line green table-container" : ""}`}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Title level={5} style={{ fontWeight: "400" }}>
              Застройщиков Иван {employee.id}
            </Typography.Title>
            <Dropdown overlay={menu(employee.id)} trigger={["click"]}>
              <MoreOneIcon
                style={{ cursor: "pointer" }}
                width={24}
                height={24}
              />
            </Dropdown>
          </div>

          <Row
            gutter={[16, 16]}
            style={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {tableMock.map(({ id, title, info, isCopy, isEmail }) => (
              <Fragment key={id}>
                <Col span={8} style={{ color: "#888888" }}>
                  {title}
                </Col>
                <Col span={16} className="table-info-content">
                  {isEmail ? <Link to={info}>{info}</Link> : null}
                  {!isEmail && !isCopy ? <div>{info}</div> : null}
                  {isCopy ? (
                    <div className="flex-between">
                      <div>{info}</div>
                      <div className="flex-default">
                        <img
                          width={16}
                          height={16}
                          alt=""
                          src={PdfIcon}
                          style={{ marginRight: "8px", cursor: "pointer" }}
                        />
                        <img
                          width={16}
                          height={16}
                          alt=""
                          src={SigIcon}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  ) : null}
                </Col>
              </Fragment>
            ))}
          </Row>
          {employee.isRepresented && (
            <div className="represented-container">
              <div
                className="flex-between"
                style={{ fontSize: 16, marginBottom: "16px" }}
              >
                <div className="flex-default">
                  <div className="represented-icon-container">
                    <SuccessIcon />
                  </div>
                  Подписано
                </div>
                <div>11.07.2024 13:10</div>
              </div>
              <div style={{ fontSize: 12, marginBottom: 8 }}>
                Доверенность № 123-32b-1w1e-77er
              </div>
              <div style={{ fontSize: 12 }}>с 11.07.2024 по 11.07.2025</div>
            </div>
          )}
        </div>
      ))}
      {!isHideButtons && (
        <Button type="primary" style={{ marginTop: "14px" }} onClick={onOpen}>
          Добавить сотрудника
        </Button>
      )}
      <RepresentationMoodal
        isRepresentation={isOpen}
        handleClose={onClose}
        handleAdd={handleAdd}
      />
    </div>
  );
};
