import { useMemo } from "react";
import { mergeProjectArraysWithDedupe } from "@entities/project";
import { useGetProjects } from "@entities/project/queries/use-get-projects.ts";
import { ProjectPageSkeleton } from "@pages/projects";
import { ProjectList } from "./project-list";
import { Empty } from "./projectempty";

const ProjectsPage = () => {
  const [
    { data: legacyData, isLoading: isLegacyLoading },
    { data, isLoading },
  ] = useGetProjects();

  const allProjects = useMemo(
    () => mergeProjectArraysWithDedupe(legacyData?.entityList, data?.projects),
    [legacyData?.entityList, data?.projects],
  );

  if (isLegacyLoading && isLoading) {
    return <ProjectPageSkeleton />;
  }

  return !allProjects.length ? (
    <Empty />
  ) : (
    <ProjectList projects={allProjects} />
  );
};

export default ProjectsPage;
