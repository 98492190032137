import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { ProjectGeneralView } from "./ui/project-general-view";
import { routes } from "@shared/lib/react-router";

export const createProjectGeneralView = (
  ...rest: RouteObject[]
): RouteObject => {
  return {
    path: `${routes.general.root()}`,
    element: createElement(ProjectGeneralView),
    children: [...rest],
  };
};
