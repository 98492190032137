import "./navigation-menu.scss";
import { ReactNode } from "react";
import { Tooltip } from "antd";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import {
  DocstorePatch,
  JournalsPatch,
  ObjectPatch,
  RequisitesPatch,
  StatisticsPatch,
} from "./icon.patches";

const menuItems: {
  link: (projectId: string) => string;
  iconPatches: ReactNode;
  label: string;
}[] = [
  {
    link: (projectId) => routes.objects.root(projectId),
    label: "Объекты",
    iconPatches: <ObjectPatch />,
  },
  {
    link: (projectId) => routes.project.requisites(projectId),
    label: "Реквизиты",
    iconPatches: <RequisitesPatch />,
  },
  {
    link: (projectId) => routes.project.statistics(projectId),
    label: "Статистика",
    iconPatches: <StatisticsPatch />,
  },
  {
    link: (projectId) => routes.project.docstore(projectId),
    label: "Документарий",
    iconPatches: <DocstorePatch />,
  },
  {
    link: (projectId) => routes.journals.title(projectId),
    label: "Журналы",
    iconPatches: <JournalsPatch />,
  },
];
export const NavigationMenu = ({
  collapsed,
  projectId,
}: {
  collapsed: boolean;
  projectId: string;
}) => {
  return (
    <div
      className={`left-sider-project-menu ${collapsed ? "left-sider-project-menu-collapsed" : ""}`}
    >
      {menuItems.map((item) => (
        <Tooltip
          placement="right"
          title={collapsed ? item.label : undefined}
          key={item.label}
        >
          <Link
            className="menu-item-link"
            to={item.link(projectId)}
            onClick={() => {
              console.log(item.link(projectId));
            }}
          >
            <motion.svg
              animate={{ position: "absolute", x: collapsed ? 12 : 0 }}
              transition={{ duration: 0.5 }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {item.iconPatches}
            </motion.svg>
            <motion.span
              initial={{
                position: "absolute",
                marginLeft: "32px",
                x: collapsed ? 36 : -36,
              }}
              animate={{ opacity: collapsed ? 0 : 1, x: collapsed ? 16 : 0 }}
              transition={{ duration: 0.5 }}
            >
              {item.label}
            </motion.span>
          </Link>
        </Tooltip>
      ))}
    </div>
  );
};
