const componentNames = {
  InfoTable: { en: "journals-info-table", ru: "Информация об объекте" },
  IntelligenceTable: {
    en: "journals-intelligence-table",
    ru: "Сведения о застройщике, эксплуатирующей организации или региональном операторе",
  },
  RepresentationTable: {
    en: "journals-representation-table",
    ru: "Уполномоченный представитель застройщика",
  },
  TecCustomerTable: {
    en: "journals-tec-customer-table",
    ru: "Технический заказчик",
  },
  CommissionerTable: {
    en: "journals-commissioner-table",
    ru: "Уполномоченный представитель технического заказчика",
  },
  PermissionTable: {
    en: "journals-permission-table",
    ru: "Разрешение на строительство",
  },
  DocumentationTable: {
    en: "journals-documentation-table",
    ru: "Лицо, осуществляющее подготовку проектной документации",
  },
  SupervisionTable: {
    en: "journals-supervision-table",
    ru: "Авторский надзор",
  },
  ExpertiseTable: {
    en: "journals-expertise-table",
    ru: "Сведения о положительном заключении экспертизы проектной документации",
  },
  ConstructionTable: {
    en: "journals-construction-table",
    ru: "Лицо, осуществляющее строительство",
  },
  RepresentativeTable: {
    en: "journals-representative-table",
    ru: "Уполномоченный представитель лица, осуществляющего строительство",
  },
  CustomerTable: {
    en: "journals-customer-table",
    ru: "Уполномоченный застройщика или тех заказчика по вопросам стройконтроля",
  },
  Epresentative: {
    en: "responsible-representative-table",
    ru: "Уполномоченный представитель лица, ответственного за эксплуатацию здания, сооружения, или регионального оператора",
  },
  ConstructionRepresentative: {
    en: "journals-construction-representative-table",
    ru: "Уполномоченный представитель лица, осуществляющего строительство, по вопросам строительного контроля",
  },
  OperationTable: {
    en: "journals-operation-table",
    ru: "Уполномоченный представитель лица, ответственного за эксплуатацию здания, сооружения, и (или) регионального оператора по вопросам строительного контроля",
  },
  OtherTable: {
    en: "journals-other-table",
    ru: "Другие лица, осуществляющие строительство, их уполномоченные представители",
  },
  ConstructionDetailsTable: {
    en: "journals-construction-details-table",
    ru: "Общие сведения об объекте капитального строительства",
  },
} as const;

type IsNameType = (typeof componentNames)[keyof typeof componentNames];

type JournalsType = {
  isShow: boolean;
  info: null | string;
  isSign: boolean | null;
  isName: IsNameType;
};

const initialState: Record<string, JournalsType> = {
  InfoTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.InfoTable,
  },
  IntelligenceTable: {
    isShow: true,
    info: null,
    isSign: null,
    isName: componentNames.IntelligenceTable,
  },
  RepresentationTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.RepresentationTable,
  },
  TecCustomerTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.TecCustomerTable,
  },
  CommissionerTable: {
    isShow: true,
    info: null,
    isSign: null,
    isName: componentNames.CommissionerTable,
  },
  PermissionTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.PermissionTable,
  },
  DocumentationTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.DocumentationTable,
  },
  SupervisionTable: {
    isShow: true,
    info: null,
    isSign: null,
    isName: componentNames.SupervisionTable,
  },
  ExpertiseTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.ExpertiseTable,
  },
  ConstructionTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.ConstructionTable,
  },
  RepresentativeTable: {
    isShow: true,
    info: null,
    isSign: null,
    isName: componentNames.RepresentativeTable,
  },
  CustomerTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.CustomerTable,
  },

  EpresentativeTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.Epresentative,
  },
  ConstructionRepresentativeTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.ConstructionRepresentative,
  },

  OperationTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.OperationTable,
  },
  OtherTable: {
    isShow: true,
    info: null,
    isSign: null,
    isName: componentNames.OtherTable,
  },
  ConstructionDetailsTable: {
    isShow: true,
    info: null,
    isSign: false,
    isName: componentNames.ConstructionDetailsTable,
  },
};

export { initialState };
export type { IsNameType, JournalsType };
