import { useEffect } from "react";
import { message, Splitter } from "antd";
import { Outlet } from "react-router-dom";
import { PrivateCheck } from "@app/composition/layouts/user/ui/private/private-check.tsx";
// import { useModulesQuery } from "@entities/user";
import { Notifications, useModalStore } from "@features/notifications";
// import { UserService } from "@shared/api/user";
import { Footer } from "./footer";
import { UserHeader } from "./header/header";

export const UserLayout = () => {
  const { isOpen } = useModalStore();

  const [messageApi, contextHolder] = message.useMessage();
  // const { data } = useModulesQuery();

  // const { data: oldUser } = useQuery({
  //   queryFn: UserService.oldService_UserCurrentQuery,
  //   queryKey: ["user", "old", "current"],
  // });
  const timeToUpdate = () => {
    return messageApi.open({
      type: "loading",
      content: "Обновление токенов.. пока чисто юи прикол",
      duration: 3,
    });
  };
  useEffect(() => {
    timeToUpdate();
  }, []);

  return (
    <PrivateCheck>
      <Splitter style={{ minHeight: "calc(100vh - 104px)" }}>
        <Splitter.Panel
          style={{ padding: "0px 0px" }}
          defaultSize="85%"
          min="70%"
          max="100%"
        >
          {contextHolder}
          <UserHeader />
          <main
            style={{
              minHeight: "calc(100vh - 104px)",
              display: "flex",
              position: "relative",
            }}
          >
            <Outlet />
          </main>
          <Footer />
        </Splitter.Panel>
        {isOpen && (
          <Splitter.Panel min="15%" max="30%" resizable={isOpen}>
            <Notifications />
          </Splitter.Panel>
        )}
      </Splitter>
    </PrivateCheck>
  );
};
