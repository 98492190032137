import { useMutation } from "@tanstack/react-query";
import { UserService, UserQueriesTags } from "@shared/api/user";

export function useFindUserByEmailMutation() {
  return useMutation({
    mutationFn: (data: { email: string }) =>
      UserService.oldService_findUserByEmailMutation(data),
    mutationKey: UserQueriesTags.findUserByEmail(),
  });
}
