import { Dispatch, SetStateAction } from "react";
import { Modal, Checkbox, Button, Space, Select } from "antd";
import { JournalsType } from "../../model/title-info-mock";
import { useSearchParams } from "react-router-dom";
import {
  journalInfoType,
  signStatusEnum,
  STATUS_MAP,
  StatusType,
} from "@/app/composition/views/project-journals/ui/project-journals";

interface ISettingModal {
  journalsList: Record<string, JournalsType>;
  setJournalsList: Dispatch<SetStateAction<Record<string, JournalsType>>>;
  journalInfo: journalInfoType;
  setJournalInfo: React.Dispatch<React.SetStateAction<journalInfoType>>;
  signStatus: signStatusEnum;
  setSignStatus: React.Dispatch<React.SetStateAction<signStatusEnum>>;
}

export const SettingModal = ({
  journalsList,
  setJournalsList,
  journalInfo,
  setJournalInfo,
  signStatus,
  setSignStatus,
}: ISettingModal) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isOpen = searchParams.get("settings-admin") === "open";

  const handleSaveStatus = (newStatusKey: StatusType) => {
    setJournalInfo((prev) => ({
      ...prev,
      currentStatus: newStatusKey,
    }));
  };
  const handleClose = () => {
    searchParams.delete("settings-admin");
    setSearchParams(searchParams);
  };

  const handleCheckboxChange = (key: string) => {
    setJournalsList((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isShow: !prevState[key].isShow,
      },
    }));
  };
  const handleSelectAll = () => {
    setJournalsList((prevState) =>
      Object.fromEntries(
        Object.entries(prevState).map(([key, value]) => [
          key,
          { ...value, isShow: true },
        ]),
      ),
    );
  };

  const handleClearAll = () => {
    setJournalsList((prevState) =>
      Object.fromEntries(
        Object.entries(prevState).map(([key, value]) => [
          key,
          { ...value, isShow: false },
        ]),
      ),
    );
  };

  const toggleMessage = () => {
    setJournalInfo((prev) => ({
      ...prev,
      message: prev.message
        ? null
        : {
            color: "#4169e1",
            messageText:
              "Вас назначили %НазваниеРоли в объект %ИмяОбъекта. Подпишите назначение на Титульном листе Журнала общих работ",
            textButton: "Посмотреть",
            buttonLgoic: () => {
              console.log("Посмотреть");
            },
          },
    }));
  };

  const toggleIsSignedTables = () => {
    setJournalInfo((prev) => ({
      ...prev,
      isSignedTables: !prev.isSignedTables,
    }));
  };

  const toggleIsButtonTables = () => {
    setJournalInfo((prev) => ({
      ...prev,
      isHideButtons: !prev.isHideButtons,
    }));
  };

  const toggleIsTablesTables = () => {
    setJournalInfo((prev) => ({
      ...prev,
      isHideTableInfo: !prev.isHideTableInfo,
    }));
  };

  const handleSignStatusChange = (value: signStatusEnum) => {
    setSignStatus(value);
  };

  return (
    <Modal
      title="Настройки отображения журналов"
      open={isOpen}
      onOk={handleClose}
      onCancel={handleClose}
      okText="Сохранить"
      cancelText="Отмена"
    >
      <Space style={{ marginBottom: 16 }}>
        <Button onClick={handleSelectAll}>Выбрать все</Button>
        <Button onClick={handleClearAll}>Сбросить все</Button>
      </Space>

      {Object.entries(journalsList).map(([key, value]) => (
        <div key={key} style={{ marginBottom: 8 }}>
          <Checkbox
            checked={value.isShow}
            onChange={() => handleCheckboxChange(key)}
          >
            {value.isName.ru}
          </Checkbox>
        </div>
      ))}
      <div className="separator" />
      <div style={{ marginBottom: 8 }}>
        <Checkbox checked={!!journalInfo.message} onChange={toggleMessage}>
          Вывести сообщение о подписании
        </Checkbox>
      </div>
      <div className="separator" />
      <div style={{ marginBottom: 8 }}>
        <Checkbox
          checked={!!journalInfo.isSignedTables}
          onChange={toggleIsSignedTables}
        >
          Подписать подписаные таблицы
        </Checkbox>
      </div>
      <div className="separator" />
      <div style={{ marginBottom: 8 }}>
        <Checkbox
          checked={!!journalInfo.isHideButtons}
          onChange={toggleIsButtonTables}
        >
          Скрыть кнопки
        </Checkbox>
      </div>
      <div style={{ marginBottom: 8 }}>
        <Checkbox
          checked={!!journalInfo.isHideTableInfo}
          onChange={toggleIsTablesTables}
        >
          Скрыть запоплнение таблиц
        </Checkbox>
      </div>
      <div>
        Установление статуса журнала
        <Select
          value={journalInfo.currentStatus.label} // Отображаем label текущего статуса
          //@ts-ignore
          onChange={(value) => handleSaveStatus(STATUS_MAP[value])}
          style={{ width: "100%", marginTop: "16px" }}
        >
          {Object.entries(STATUS_MAP).map(
            ([key, { label, textColor, backgroundColor }]) => (
              <Select.Option key={key} value={key}>
                <span
                  style={{
                    color: textColor,
                    backgroundColor,
                    padding: "2px 6px",
                    borderRadius: "4px",
                  }}
                >
                  {label}
                </span>
              </Select.Option>
            ),
          )}
        </Select>
      </div>
      <div className="separator" />
      <div style={{ marginBottom: 16 }}>
        <span>Установление статуса подписания:</span>
        <Select
          value={signStatus}
          onChange={handleSignStatusChange}
          style={{ width: "100%", marginTop: "8px" }}
        >
          {Object.values(signStatusEnum).map((status) => (
            <Select.Option key={status} value={status}>
              {status}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Modal>
  );
};
