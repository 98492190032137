import { Typography, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CustomDatePicker } from "./ui";

export const JournalsConstructionDetailsTable = () => {
  return (
    <div className="table-container" id="journals-construction-details-table">
      <Typography.Title level={4}>
        Общие сведения об объекте капитального строительства
      </Typography.Title>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "14px" }}
      >
        <div>Наименование объекта капитального строительства</div>
        <Select placeholder="Объект" style={{ marginTop: "4px" }} />
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "24px" }}
      >
        <div>Краткие проектные характеристики объекта</div>
        <TextArea
          style={{ marginTop: "4px" }}
          placeholder="Характеристики"
          rows={4}
        />
        <CustomDatePicker />
      </div>
    </div>
  );
};
