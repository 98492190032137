import { Typography } from "antd";
import { Outlet } from "react-router-dom";
import "./project-objects-view.scss";
import { Breadcrumbs } from "@/features/breadcrumb";
import { ObjectTabs } from "@/features/object-tabs";
import { routes } from "@/shared/lib/react-router";

const { Title } = Typography;

const routesMock = [
  { path: "", label: "Проекты" },
  { path: routes.project.root({ projectId: "312" }), label: "Загородный дом" },
  { path: routes.objects.root(), label: "Объекты" },
];

export const ProjectObjectsView = () => {
  return (
    <div className="general-view-container">
      <Breadcrumbs routes={routesMock} />
      <Title level={3}>Жилой дом</Title>
      <ObjectTabs />
      <Outlet />
    </div>
  );
};
