import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { ObjectInfo } from "./ui";

export const ObjectInfoRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.objects.object()}`,
    element: createElement(ObjectInfo),
    children: [...rest],
  };
};
