import { withErrorBoundary } from "react-error-boundary";
import { AntdGlobalProvider } from "@app/providers/with-antd";
import { CaslProvider } from "@app/providers/with-casl";
import { QueryClientProvider } from "@app/providers/with-query-client";
import { BrowserRouter } from "@app/providers/with-router";
import { withSuspense } from "@app/providers/with-suspense";
// import {GlobalErrorPage} from '@pages/error';
import "../styles/index.css";
import { FullPageError } from "@pages/error";

function Providers() {
  return (
    <AntdGlobalProvider>
      <QueryClientProvider>
        <CaslProvider>
          <BrowserRouter />
        </CaslProvider>
      </QueryClientProvider>
    </AntdGlobalProvider>
  );
}

const SuspenseProvider = withSuspense(Providers, {
  fallback: <div>загрузка</div>,
});
export const Provider = withErrorBoundary(SuspenseProvider, {
  fallbackRender: ({ error }) => <FullPageError error={error} />,
});
