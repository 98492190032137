import { useState } from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, DatePicker, Button } from "antd";
import { useForm, Controller } from "react-hook-form";
import {
  ObjectFormDate,
  ObjectFormInput,
  ObjectFormInputDate,
  ObjectFormRadio,
} from "..";
import "./object-form-create.scss";
import { ObjectFormLoadFile } from "../object-form-load-file/object-form-load-file";
import { ObjectModalAddress } from "../object-modal-add-address/object-modal-add-address";

type FormType = {
  constructionEnd: string;
  constructionStart: string;
  constructionType: string;
  expertiseDate: string;
  expertiseNumber: string;
  isTemporary: string;
  isTemporaryExpertise: string;
  issueDate: string;
  objectDescription: string;
  objectName: string;
  objectNameShort: string;
  permitNumber: string;
  test: string;
};

interface IObjectFormCreate {
  handleIsForm: (event: any, status: boolean) => void;
}

export const ObjectFormCreate = ({ handleIsForm }: IObjectFormCreate) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>();

  const [isModalAddress, setIsModalAddress] = useState(false);
  const handleSetIsModalAddress = () => {
    setIsModalAddress(true);
  };
  const onSubmit = (data: FormType) => {
    console.log(data);
  };
  return (
    <div className="project-object-wrapper">
      <div className="project-object-container">
        <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <div className="form-section">
            <ObjectFormInput
              name="objectName"
              control={control}
              label="Краткое наименование обьекта"
              placeholder="Жилой дом"
              width="50%"
              errorMessage={errors.objectName?.message}
            />
            <ObjectFormInput
              name="objectNameShort"
              control={control}
              label="Наименование объекта капитального строительства в соответствии с проектной документацией"
              placeholder="Жилой двухэтажный каркасный дом синего цвета с блестящей трубой снаружи"
              errorMessage={errors.objectNameShort?.message}
            />
            <ObjectFormInput
              name="objectNameShort"
              control={control}
              label="Краткие проектные характеристики"
              placeholder="Удовлетворительные"
              errorMessage={errors.objectNameShort?.message}
            />
            <ObjectFormInput
              name="objectNameShort"
              control={control}
              label="Описание этапа строительства, реконструкции (необ.)"
              placeholder="Бесконечное строительство"
              errorMessage={errors.objectNameShort?.message}
            />
            <ObjectFormRadio
              name="constructionType"
              label="Вид строительства"
              control={control}
              radioOptions={[
                "Строительство",
                "Реконструкция",
                "Капитальный ремонт",
              ]}
            />
          </div>

          <div className="form-section address">
            <h3>Адрес</h3>
            <div className="form-address-button">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleSetIsModalAddress}
              >
                Добавить адрес
              </Button>
            </div>

            {/* <div className="object-form-error">Адрес должен быть добавлен</div> */}
          </div>

          <div className="form-section">
            <h3>Разрешение на строительство</h3>
            <ObjectFormRadio
              name="test"
              control={control}
              radioOptions={["Требуется", "Не требуется"]}
            />
            <div>
              Заполняется если разрешение на строительство требуется в
              соответствии со ст. 51 Градостроительного кодекса РФ
            </div>
            <ObjectFormInputDate
              nameFirst="permitNumber"
              nameSecond="issueDate"
              control={control}
              labelFirst=""
              labelSecond=""
              placeholderFirst="№ разрешения"
              placeholderSecond="Дата выдачи"
              errorMessage={errors?.permitNumber?.message}
            />
            <ObjectFormInput
              name="test"
              control={control}
              label="Наименование органа, выдавшего разрешение"
              placeholder=""
              errorMessage={errors.test?.message}
            />
            <ObjectFormDate
              nameFirst="test"
              nameSecond="test"
              control={control}
              labelFirst=""
              labelSecond=""
              placeholderFirst="Срок действия (необязательно)"
              placeholderSecond="Продлен до (необязательно)"
              errorMessage={errors?.test?.message}
            />
            <ObjectFormLoadFile />
            <div className="dottedLine" />
            <Button
              type="dashed"
              style={{
                width: "auto",
                marginTop: "24px",
                backgroundColor: "#EAEEF8",
              }}
            >
              Добавить еще разрешение на строительство
            </Button>
          </div>

          <div className="form-section">
            <h3>
              Сведения о положительном заключении экспертизы проектной
              документации
            </h3>
            <ObjectFormRadio
              name="test"
              control={control}
              radioOptions={["Требуется", "Не требуется"]}
            />
            <div>
              Заполняется если в соответствии со ст.49 Градостроительного
              кодекса РФ проводится экспертиза проектной документации
            </div>
            <div className="field-group">
              <Controller
                name="expertiseNumber"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="№ заключения" />
                )}
              />
              <Controller
                name="expertiseDate"
                control={control}
                render={({ field }) => (
                  <DatePicker {...field} placeholder="Дата выдачи" />
                )}
              />
            </div>
          </div>

          <div className="form-section">
            <h3>Сроки строительства</h3>
            <div className="field-group">
              <Controller
                name="constructionStart"
                control={control}
                render={({ field }) => (
                  <DatePicker {...field} placeholder="Начало" />
                )}
              />
              <Controller
                name="constructionEnd"
                control={control}
                render={({ field }) => (
                  <DatePicker {...field} placeholder="Окончание" />
                )}
              />
            </div>
          </div>
          <div className="object-button-container">
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </div>
        </form>
        <CloseOutlined
          className="object-form-icon-close"
          onClick={(event) => handleIsForm(event, false)}
        />
      </div>
      <ObjectModalAddress
        isModalAddress={isModalAddress}
        setIsModalAddres={setIsModalAddress}
      />
    </div>
  );
};
