import { AxiosRequestConfig, AxiosResponse } from "axios";
import { userServiceApi, oldBaseApi } from "@shared/api";
import {
  LoginUserDto,
  RegisterUserDto,
  UserModulesDto,
  UserResponse,
} from "./types";

export class UserService {
  static loginUserMutation(data: {
    LoginUserDto: LoginUserDto;
  }): Promise<AxiosResponse<UserResponse>> {
    return userServiceApi.post("login", data);
  }

  static registerUserMutation(data: {
    RegisterUserDto: RegisterUserDto;
  }): Promise<AxiosResponse<UserResponse>> {
    return userServiceApi.post("register", data);
  }

  static userModulesQuery(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<UserModulesDto>> {
    return userServiceApi.get("api/v1/users/modules", config || {});
  }

  static oldService_UserCurrentQuery(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<UserModulesDto>> {
    return oldBaseApi.get("api/users/current", config || {});
  }

  static oldService_loginUserMutation(data: {
    LoginUserDto: LoginUserDto;
  }): Promise<AxiosResponse<UserResponse>> {
    return oldBaseApi.post("login", data);
  }

  static oldService_findUserByPhoneMutation(data: {
    phone: string;
  }): Promise<AxiosResponse<UserResponse>> {
    return oldBaseApi.get(`api/users/byphone/${data.phone}`);
  }

  static oldService_refreshToken(refreshToken: string): Promise<
    AxiosResponse<{
      jwtToken: string;
      refreshToken: string;
      keycloak: {
        accessToken: string;
        expiresIn: number;
        refreshToken: string;
        tokenType: string;
      };
    }>
  > {
    return oldBaseApi.post(`api/tokens/jwt/refresh`, refreshToken);
  }
}
