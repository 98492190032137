import { ReactNode, useState } from "react";
import { Button, Collapse, List, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import "./project-related-contracts.scss";
import { routes } from "@/shared/lib/react-router";
import { ArrowIcon } from "@/shared/ui";

const { Panel } = Collapse;
const { Text, Title } = Typography;

const data = [
  {
    id: 1,
    title:
      "РТК-10/21 Комплекс работ по укрытию защитной сеткой существующих ветхих зданий имущественного комплекса нефтемаслозавода",
    isNested: true,
    nestedItems: [
      "ГКО-639/22 Комплекс строительно-монтажных работ по свайному основанию...",
      "ГКО-713/20 (КН-112/20) Монтаж ограждения",
      "ГКО-668/22 Комплекс строительно-монтажных работ...",
    ],
  },
  {
    id: 2,
    title: "РТК-15/22 (ГКО-757/22)",
    isNested: true,
    nestedItems: [
      "ГКО-639/22 Комплекс строительно-монтажных работ по свайному основанию...",
      "ГКО-713/20 (КН-112/20) Монтаж ограждения",
      "ГКО-668/22 Комплекс строительно-монтажных работ...",
    ],
  },
  {
    id: 3,
    title:
      "ГКО-668/22 Комплекс строительно-монтажных работ по устройству временных дорог, разработке котлована, устройству шпунтового ограждения котлована с распорной системой",
    isNested: false,
  },
];

export const ProjectRelatedContracts = () => {
  const [expandedKeys, setExpandedKeys] = useState<number[]>([]);

  const toggleAll = () => {
    if (expandedKeys.length === data.filter((item) => item.isNested).length) {
      setExpandedKeys([]);
    } else {
      setExpandedKeys(
        data.filter((item) => item.isNested).map((item) => item.id),
      );
    }
  };

  const handlePanelChange = (key: number) => {
    if (expandedKeys.includes(key)) {
      setExpandedKeys(expandedKeys.filter((id) => id !== key));
    } else {
      setExpandedKeys([...expandedKeys, key]);
    }
  };

  const { projectId } = useParams();

  return (
    <>
      <div className="project-related-title-container">
        <Title
          style={{
            fontSize: "20px",
            color: "#333333",
            fontWeight: "500",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          Договоры
        </Title>
        <Button onClick={toggleAll} type="link">
          Свернуть/Развернуть все
        </Button>
      </div>

      <div className="project-related-contracts">
        <List
          dataSource={data}
          renderItem={(item) =>
            item.isNested ? (
              <Collapse
                ghost
                activeKey={expandedKeys}
                onChange={() => handlePanelChange(item.id)}
                expandIcon={({ isActive }) => (
                  <ArrowIcon isActive={isActive as boolean} />
                )}
              >
                <Panel
                  header={<Text>{item.title}</Text>}
                  key={item.id}
                  style={{ border: "none" }}
                >
                  <List
                    dataSource={item.nestedItems}
                    renderItem={(nestedItem) => (
                      <Link
                        to={`${routes.general.related(projectId)}`}
                        style={{ paddingLeft: 21 }}
                      >
                        <Text className="procject-related-link">
                          {nestedItem as ReactNode}
                        </Text>
                      </Link>
                    )}
                  />
                </Panel>
              </Collapse>
            ) : (
              <List.Item>
                <Link to={`${routes.general.related(projectId)}`}>
                  <Text className="procject-related-link not-nested">
                    {item.title}
                  </Text>
                </Link>
              </List.Item>
            )
          }
        />
      </div>
    </>
  );
};
