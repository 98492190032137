import { useQuery } from "@tanstack/react-query";
import {
  ProjectService,
  ProjectQueriesTags,
  ModernApi,
} from "@shared/api/project";

interface IUseGetProject {
  projectId: string;
  idType?: "projectId" | "externalId";
}

export function useProject({ projectId, idType }: IUseGetProject) {
  const query = useQuery({
    queryFn: () =>
      ProjectService.getProject({ projectId, idType: idType || "projectId" }),
    queryKey: ProjectQueriesTags.getProject({ projectId }),
  });
  return {
    ...query,
    data: query.data as ModernApi.ProjectResponse,
  };
}
