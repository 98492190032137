import { ActionIcon, DocumIcon, OjrIcon } from "@shared/assets/svg/icons";

import "./project-details.scss";

interface IProjectDetails {
  tasks?: number;
  files?: number;
  folders?: number;
}

export const ProjectDetails = ({
  tasks = 0,
  files = 0,
  folders = 0,
}: IProjectDetails) => {
  return (
    <div className="project-details">
      {
        <div className="detail-item" data-tooltip="Акты ожидающие действия">
          <ActionIcon width={24} height={24} />{" "}
          <div className="detail-item-count">{tasks}</div>
        </div>
      }
      {
        <div className="detail-item" data-tooltip="Штампы на подписание">
          <DocumIcon width={24} height={24} />{" "}
          <div className="detail-item-count">{files}</div>
        </div>
      }
      {
        <div className="detail-item" data-tooltip="Журналы действия">
          <OjrIcon width={24} height={24} />{" "}
          <div className="detail-item-count">{folders}</div>
        </div>
      }
    </div>
  );
};
