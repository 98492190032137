import { ChangeEvent, useState } from "react";
import { Button, Input, notification } from "antd";
import "./representation-form-email.scss";
import { User } from "@entities/user";
import { useFindUserByPhoneMutation } from "@entities/user/queries/useFindUserByPhoneMutation.ts";

interface IRepresentationFormEmail {
  setIsShowFormAdd?: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess?: (user: User) => void;
}

export const RepresentationFormEmail = ({
  setIsShowFormAdd,
  onSuccess,
}: IRepresentationFormEmail) => {
  const [emailValue, setEmailValue] = useState("");
  const { mutateAsync, isPending } = useFindUserByPhoneMutation();
  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };
  const [api, contextHolder] = notification.useNotification();

  const handleAddRepresentation = () => {
    mutateAsync({ phone: emailValue })
      .then((result) => {
        if (onSuccess) {
          console.log(result);
          if (!result.data) {
            api.error({
              message: `Сотрудник не найден`,
              description: "Попробуйте другие данные",
              placement: "topRight",
              showProgress: false,
            });
          } else {
            onSuccess(result.data as User);
          }
        }
      })
      .catch(() => {
        api.error({
          message: `Неудачный запрос`,
          description:
            "Это временно, потом сделаем крутую обработку и подсвечивание инпута. И блокировку кнопок тоже",
          placement: "topRight",
          showProgress: false,
        });
      });
    if (setIsShowFormAdd) {
      setIsShowFormAdd(true);
    }
  };
  return (
    <div className="email-input-container">
      {contextHolder}
      <div className="email-inpit-lable">
        <div className="email-input-title">Электронная почта</div>
        <Input
          placeholder="почта"
          style={{ minWidth: "320px" }}
          value={emailValue}
          onChange={handleEmail}
        />
      </div>
      <Button
        type="primary"
        loading={isPending}
        disabled={emailValue.length < 11}
        onClick={handleAddRepresentation}
      >
        Найти
      </Button>
    </div>
  );
};
