import { useAbility } from "@casl/react";
import { Layout, Spin } from "antd";
import { Outlet } from "react-router-dom";
import { useProject } from "@entities/project/";
import {
  AbilityContext,
  ProjectAbilityBuilder,
  useUpdateAbility,
} from "@shared/lib/casl";
import { useCoordinator } from "@shared/lib/hooks";
import Navigation from "./navigation";

const ProjectWrapper = () => {
  const { projectId } = useCoordinator() as {
    projectId: string;
  };
  const { data: project, isLoading } = useProject({ projectId });

  const ability = useAbility(AbilityContext);

  useUpdateAbility({
    entity: project,
    currentAbility: ability,
    abilityBuilder: ProjectAbilityBuilder,
  });

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 104px)",
          minWidth: "100vw",
        }}
      >
        <Spin size="large" />
      </div>
    );

  return (
    <Layout>
      <Navigation />
      <Outlet />
    </Layout>
  );
};

export default ProjectWrapper;
