import { useState } from "react";
import { Radio, Row, Col, Button, Input } from "antd";
import "./signature-modal-select.scss";
import { Link } from "react-router-dom";

interface ISignatureModalSelect {
  addObject: () => void;
  setAddingAttorney: React.Dispatch<React.SetStateAction<boolean>>;
}

const mockData = [
  {
    method: "По номеру",
    fields: {
      Номер: "123F3210-9X12-9X12-7DA6A65E654B",
      ИНН: "0123456789",
      Доверитель: "ООО “Заказчик”",
      "Доверенное лицо": "Заказчиков Иван Иванович",
      "Дата выдачи": "15.05.2022",
      "Дата окончания": "15.05.2024",
    },
  },
  {
    method: "Загрузить файл",
    fields: {
      "Файл и подпись": ["Моя доверенность.xml", "Signature.sig"],
      Доверитель: "7839136848",
      "Доверенное лицо": "783901001",
      "Дата выдачи": "ГОСТ Р 34. 10-2012 256 Бит",
      "Дата окончания": "15.05.2022",
    },
  },
];

export const SignatureModalSelect = ({
  addObject,
  setAddingAttorney,
}: ISignatureModalSelect) => {
  const [selectedMethod, setSelectedMethod] = useState("По номеру");

  const handleMethodChange = (e: any) => {
    setSelectedMethod(e.target.value);
  };

  const handleAddLocal = () => {
    setAddingAttorney(false);
    addObject();
  };

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center" }}
      >
        <div style={{ paddingTop: 16, paddingBottom: 16, marginRight: 24 }}>
          Метод добавления
        </div>
        <Radio.Group
          onChange={handleMethodChange}
          value={selectedMethod}
          style={{ gap: "16px" }}
        >
          {mockData.map((data) => (
            <Radio
              key={data.method}
              value={data.method}
              style={{
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              {data.method}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      <div className="">
        {selectedMethod === "По номеру" ? (
          <div style={{ padding: "16px" }}>
            <Row gutter={[16, 16]} align="middle">
              <Col span={6}>
                <label
                  style={{
                    display: "block",
                    color: "#8C8C8C",
                    fontSize: "14px",
                  }}
                >
                  Номер
                </label>
              </Col>
              <Col span={12}>
                <Input
                  value="123F3210-9X12-9X12-7DA6A65E654B"
                  style={{ borderRadius: "10px" }}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: "16px" }}>
              <Col span={6}>
                <label
                  style={{
                    display: "block",
                    color: "#8C8C8C",
                    fontSize: "14px",
                  }}
                >
                  ИНН доверителя
                </label>
              </Col>
              <Col span={12}>
                <Input style={{ borderRadius: "10px" }} />
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ padding: "16px" }}>
            <Row gutter={[16, 16]} align="middle">
              <Col span={6}>
                <label
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Файл и подпись
                </label>
              </Col>
              <Col span={12}>
                <div>
                  <Link
                    to="#"
                    style={{
                      color: "#1A73E8",
                      fontSize: "14px",
                      textDecoration: "none",
                    }}
                  >
                    Моя доверенность.xml
                  </Link>
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                  >
                    ✕
                  </span>
                </div>
                <div style={{ marginTop: "8px" }}>
                  <Link
                    to="#"
                    style={{
                      color: "#1A73E8",
                      fontSize: "14px",
                      textDecoration: "none",
                    }}
                  >
                    Signature.sig
                  </Link>
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                  >
                    ✕
                  </span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: "16px" }}>
              <Col span={6}>
                <label
                  style={{
                    display: "block",
                    color: "#888",
                    fontSize: "14px",
                  }}
                >
                  Доверитель
                </label>
              </Col>
              <Col span={12}>
                <span style={{ color: "#333", fontSize: "14px" }}>
                  7839136848
                </span>
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: "16px" }}>
              <Col span={6}>
                <label
                  style={{
                    display: "block",
                    color: "#888",
                    fontSize: "14px",
                  }}
                >
                  Доверенное лицо
                </label>
              </Col>
              <Col span={12}>
                <span style={{ color: "#333", fontSize: "14px" }}>
                  783901001
                </span>
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: "16px" }}>
              <Col span={6}>
                <label
                  style={{
                    display: "block",
                    color: "#888",
                    fontSize: "14px",
                  }}
                >
                  Дата выдачи
                </label>
              </Col>
              <Col span={12}>
                <span style={{ color: "#333", fontSize: "14px" }}>
                  ГОСТ Р 34. 10-2012 256 Бит
                </span>
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: "16px" }}>
              <Col span={6}>
                <label
                  style={{
                    display: "block",
                    color: "#888",
                    fontSize: "14px",
                  }}
                >
                  Дата окончания
                </label>
              </Col>
              <Col span={12}>
                <span style={{ color: "#333", fontSize: "14px" }}>
                  15.05.2022
                </span>
              </Col>
            </Row>
          </div>
        )}
      </div>
      <div className="flex-default">
        <Button
          style={{ marginRight: 16 }}
          type="primary"
          onClick={handleAddLocal}
        >
          Добавить
        </Button>
        <Button type="default">Закрыть</Button>
      </div>
    </>
  );
};

