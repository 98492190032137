import { Table, Dropdown, Menu, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import "./journals-settings-integration.scss";

interface DataType {
  key: string;
  event: string;
  dateTime: string;
  status: string;
}

const data: DataType[] = [
  {
    key: "1",
    event: "Отправлены в интеграционный канал сопроводительные файлы к XML",
    dateTime: "16.08.2024 16:13 МСК",
    status: "Отправлено",
  },
  {
    key: "2",
    event: "Сформирован XML для отправки в ГСН",
    dateTime: "15.08.2024 15:13 МСК",
    status: "Сформировано",
  },
  {
    key: "3",
    event: "XML отправлен в интеграционный канал",
    dateTime: "14.08.2024 13:13 МСК",
    status: "Отправлено",
  },
  {
    key: "4",
    event: "XML успешно принят в интеграционный канал",
    dateTime: "14.08.2024 12:13 МСК",
    status: "Принято",
  },
  {
    key: "5",
    event: "XML отправлен получателю в ГСН",
    dateTime: "13.08.2024 17:29 МСК",
    status: "Отправлено",
  },
  {
    key: "6",
    event: "XML успешно принят получателем в ГСН",
    dateTime: "13.08.2024 16:27 МСК",
    status: "Принято",
  },
  {
    key: "7",
    event: "Ошибка интеграционного взаимодействия",
    dateTime: "13.08.2024 16:27 МСК",
    status: "Ошибка",
  },
  {
    key: "8",
    event: "Приняты замечания от ГСН",
    dateTime: "13.08.2024 16:27 МСК",
    status: "Принято",
  },
  {
    key: "9",
    event: "Принята регистрация сведений от ГСН",
    dateTime: "13.08.2024 16:27 МСК",
    status: "Принято",
  },
];

export const JournalsSettingsIntegration: React.FC = () => {
  const menu = (
    <Menu>
      <Menu.Item key="1">Скачать XML</Menu.Item>
      <Menu.Item key="2">Скачать JSON</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Событие",
      dataIndex: "event",
      key: "event",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Дата, Время",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (text: string) => (
        <span style={{ color: "#888888" }}>{text}</span>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (text: string) => <span>{text}</span>,
    },
    {
      key: "action",
      render: () => (
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="table-container" style={{ marginTop: "24px" }}>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        bordered={false}
        rowKey="key"
      />
    </div>
  );
};
