import { AxiosResponse } from "axios";
import { buildsServiceApi } from "@shared/api";
import { GetObjectsRequest, GetObjectsResponse } from "./types";

export class ObjectService {
  static getAllObjects(data: GetObjectsRequest): Promise<GetObjectsResponse> {
    return buildsServiceApi
      .get(`projects/${data.projectId}/building-objects`, {
        params: {
          limit: data.limit || 1000,
          page: data.page || 1,
        },
      })
      .then((r) => r.data);
  }
}
