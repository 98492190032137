import { InfoIcon, WarningIcon } from "@/shared/assets/svg/icons";
import { Button, Radio, Typography, Row, Col, Divider } from "antd";
import { Link } from "react-router-dom";
import { SignatureTable } from "../../../signature-table";
import "./signature-modal-default.scss";

const { Text } = Typography;

interface ISignatureModalDefault {
  invalid: boolean;
  error: boolean;
  handleErrorTogle: () => void;
  fields: {
    label: string;
    value: string;
  }[];
  handleInvalidTogle: () => void;
  stateData: {
    id: number;
    organization: string;
    employee: string;
    expirationDate: string;
    classification: string;
    textPermissions: string;
  }[];
  selectedId: number;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
  setAddingAttorney: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignatureModalDefault = ({
  invalid,
  error,
  handleErrorTogle,
  fields,
  handleInvalidTogle,
  stateData,
  selectedId,
  setSelectedId,
  setAddingAttorney,
}: ISignatureModalDefault) => {
  const handleOpenAddingAttorney = () => {
    setAddingAttorney(true);
  };
  return (
    <>
      <div className="signature-container">
        {invalid && !error ? (
          <div className="signature-info-tooltip">
            <InfoIcon className="signature-info-tooltip-icon" />
            <div className="signature-info-tooltip-text">
              Подпись можно сделать только валидным ключом
            </div>
          </div>
        ) : null}
        {error ? (
          <div className="signature-info-tooltip">
            <WarningIcon className="signature-info-tooltip-icon" />
            <div className="signature-info-tooltip-text">
              Не удалось найти сертификаты ЭЦП
            </div>
          </div>
        ) : (
          <div
            className={`certificate-select-container ${invalid ? "invalid" : ""}`}
          >
            <div className="certificate-select-title-container">
              <div className="certificate-select-title">
                Выберите сертификат
              </div>
              <Button type="link" onClick={handleErrorTogle}>
                Обновить список
              </Button>
            </div>
            <Radio.Group
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "16px",
              }}
            >
              <Radio
                value="cert1"
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginBottom: "8px",
                }}
              >
                fb123123-abc1-1234-abc2-a1111xxx12a0
              </Radio>
              <Radio
                value="cert2"
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginBottom: "8px",
                }}
              >
                Застройщиков Иван Иванович; ИНН 784300001234; СНИЛС 12012120
              </Radio>
            </Radio.Group>
          </div>
        )}

        {error ? (
          <div className="error-info-container">
            <div>Убедитесь в следующем: </div>
            <div>
              1. Рабочее место настроено (<Link to="#">Инструкция</Link>)
            </div>
            <div>2. Сертификат установлен на компьютер</div>
            <div style={{ color: "#888", marginTop: "16px" }}>
              Разрешите браузеру операции с сертификатами
            </div>
          </div>
        ) : (
          <div className="table-container table-container-signature">
            <Typography.Title
              level={5}
              style={{
                fontWeight: "400",
                color: "#000",
                lineHeight: "24px",
                marginBottom: "10px",
              }}
            >
              Застройщиков Иван
            </Typography.Title>
            <div className="signature-builder-status-container">
              <div
                className={`signature-builder-status ${invalid ? "red" : "green"}`}
                onClick={handleInvalidTogle}
              >
                {invalid ? "Не валидный" : "Валдиный"}
              </div>
              {invalid ? (
                <div className="signature-builder-status-edition">
                  Срок дейстия истек
                </div>
              ) : null}
            </div>
            {fields.map(({ label, value }: any) => (
              <Row key={label} gutter={[16, 8]} style={{ marginBottom: "8px" }}>
                <Col span={8}>
                  <Text style={{ color: "#888" }}>{label}</Text>
                </Col>
                <Col span={16}>
                  <Text>{value}</Text>
                </Col>
              </Row>
            ))}
          </div>
        )}
        <div className="certificate-select-title-container">
          <div className="certificate-select-title">Выбор доверенности</div>
          <Button type="link" onClick={handleOpenAddingAttorney}>
            Добавить
          </Button>
        </div>
        {error ? (
          <div style={{ color: "#888888" }}>Доверенность не найдена</div>
        ) : (
          <>
            {stateData.map((item: any) => (
              <SignatureTable
                key={item.id}
                data={item}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
              />
            ))}
          </>
        )}
      </div>
      <Divider style={{ marginTop: 0, marginBottom: 0 }} />
      <div className="flex-default" style={{ paddingTop: 16 }}>
        <Button type="primary" style={{ marginRight: 16 }}>
          Подписать
        </Button>
        <Button type="default">Отмена</Button>
      </div>
    </>
  );
};

