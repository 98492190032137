export class UserQueriesTags {
  static root() {
    return ["user"];
  }

  static modules() {
    return [...UserQueriesTags.root(), "query", "modules"] as const;
  }

  static login() {
    return [...UserQueriesTags.root(), "mutation", "login"] as const;
  }

  static findUserByEmail() {
    return [
      ...UserQueriesTags.root(),
      "mutation",
      "find_user_by_email",
    ] as const;
  }
}
