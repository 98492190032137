import { useQuery } from "@tanstack/react-query";
import { ProjectQueriesTags, ProjectService } from "@shared/api/project";

interface IUseProjectImage {
  projectId: string;
  imageId: string;
  enabled: boolean;
}

export function useProjectImage({
  projectId,
  imageId,
  enabled,
}: IUseProjectImage) {
  return useQuery({
    queryFn: () =>
      ProjectService.getProjectImage({
        fileId: imageId,
        type: "url",
      }),
    queryKey: ProjectQueriesTags.getProjectImage({
      projectId,
      fileId: imageId,
      type: "url",
    }),
    enabled,
  });
}
