import { useState, useEffect, useRef } from "react";
import "./project-related-tabs.scss";
import { journalInfoType } from "@/app/composition/views/project-journals/ui/project-journals";

interface IProjectRelatedTabs {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  journalInfo?: journalInfoType;
}

export const ProjectRelatedTabs = ({
  tabs,
  activeTab,
  setActiveTab,
}: IProjectRelatedTabs) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [indicatorStyle, setIndicatorStyle] = useState({
    width: "0px",
    transform: "translateX(0px)",
  });

  function getButtonWidth() {
    if (containerRef.current) {
      const activeIndex = tabs.indexOf(activeTab);
      const buttons = containerRef.current.querySelectorAll<HTMLButtonElement>(
        ".related-tab-button",
      );

      if (buttons[activeIndex]) {
        const button = buttons[activeIndex];
        const buttonOffsetWidth = button.offsetWidth;
        setIndicatorStyle({
          width: `${buttonOffsetWidth}px`,
          transform: `translateX(${button.offsetLeft - 2}px)`,
        });
      }
    }
  }

  useEffect(() => {
    getButtonWidth();
  }, [activeTab, tabs]);

  return (
    <div style={{ display: "inline-block" }}>
      <div className="related-tabs-wrapper" ref={containerRef}>
        <div
          className="related-highlight"
          style={{
            width: indicatorStyle.width,
            transform: indicatorStyle.transform,
          }}
        />
        <div className="related-tabs">
          {tabs.map((tab, index) => (
            <>
              {index === 1 ? (
                <button
                  key={tab}
                  type="button"
                  className={`related-tab-button ${
                    activeTab === tab ? "active" : ""
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ) : (
                <button
                  key={tab}
                  type="button"
                  className={`related-tab-button ${
                    activeTab === tab ? "active" : ""
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
