import { JournalsStateSupervision } from "../journals-state-supervision";
import { JournalsSupervisionOfficial } from "../journals-supervision-official";
import { JournalsSupervisionRegistration } from "../journals-supervision-registration";
import "./journals-registration-details.scss";

export const JournalsRegistrationDetails = () => {
  return (
    <div className="journals-main-column-wrapper">
      <JournalsStateSupervision />
      <JournalsSupervisionOfficial />
      <JournalsSupervisionRegistration />
    </div>
  );
};
