import "./object-info.scss";
import { format } from "date-fns";
import { TableInfoType, TableInfo } from "@/features/table-info";
import { generateConstructionTypeName, useObject } from "@entities/object";
import { useCoordinator } from "@shared/lib/hooks";
import { ObjectInfoOpinion } from "../object-info-opinion";
import { ObjectInfoPermission } from "../object-info-permission";

export const ObjectInfo = () => {
  const { objectId } = useCoordinator() as { objectId: string };
  const { data: object } = useObject({ objectId });
  const tableMock: TableInfoType[] = [
    {
      id: 1,
      title: "Краткое наименование объекта",
      info: object.shortName || "-",
    },
    {
      id: 2,
      title: "Вид строительства",
      info:
        generateConstructionTypeName(object.constructionType as string) || "-",
    },
    {
      id: 3,
      title: "Наименование объекта капитального строительства",
      info: object.fullName || "-",
    },
    {
      id: 4,
      title: "Краткие проектные характеристики",
      info: object.descriptionProjects || "-",
    },
    {
      id: 5,
      title: "Описание этапа строительства, реконструкции",
      info: object.descriptionStage || "-",
    },
    {
      id: 6,
      title:
      //@ts-ignore
        object.address.addressType === "CONSTRUCTION"
          ? "Строительный адрес"
          : "Почтовый адрес",
      info: object.address.addressString || "",
    },
    {
      id: 7,
      title: "Начало строительства",
      info: object.buildingStart
        ? format(new Date(object.buildingStart), "dd.MM.yyyy")
        : "-",
    },
    {
      id: 8,
      title: "Окончание строительства",
      info: object.buildingEnd
        ? format(new Date(object.buildingEnd), "dd.MM.yyyy")
        : "-",
    },
  ];

  return (
    <>
      <TableInfo
        tabs={tableMock}
        name="Информация об объекте строительства"
        isButton
        handleIsButton={() => {
          console.log("open");
        }}
        businessEntity="object"
        buttonName="Редактировать"
      />
      <ObjectInfoPermission data={object.documentationPermissions} />
      <ObjectInfoOpinion data={object.documentationExaminations} />
    </>
  );
};
